import React, {useEffect, useState} from 'react';
import ImgCrop from 'antd-img-crop';
import {PlusOutlined} from '@ant-design/icons';
import {Image, Upload} from 'antd';
import useUpload from "../../hooks/useUpload";
import {FILE_TYPES, IMAGE_MAX_SIZE} from "../../utils/Constants";
import styled from 'styled-components';
import {primary} from "../../utils/Colors";
import PrimeIcon from "../other/PrimeIcon";
import useBreakPoints from "../../hooks/useBreakPoints";

const Container = styled.div`
    background-color: ${primary};
    width: ${({isDesktop}) => isDesktop?  '100%': '215px'};
    height: 224px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid ${primary};
    overflow: hidden;
    position: relative;

    ${({hasImage}) => {
        if (hasImage) {
            return `
            &:hover::before{
                height: 100%;
                width: 100%;
            }
            
            &::before {
                // transition: 0.3s;
                content: '';
                width: 60px;
                position: absolute;
                bottom: 0;
                right:0;
                height: 60px;
                background: black;
                opacity: 0.5;
                filter: blur(5px);
            }
            `
        }
    }}

`

const StyledUpload = styled(Upload)`
   display: inline-block;
    //background: red;
    width: 100%;
    height: 220px;
  & > * {
      width: 100%;
  }
`
const ImagePreview = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;

`

const IconContainer = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;

    &.delete:hover{
        background: red;
    }
    & > * {
        color: white;
        height: 26px;
        font-size: 18px;
        //font-size: 16px;
    }
`

export default function ImageUploadField({
                                             accept = FILE_TYPES.IMAGES.ALL(),
                                             addonBefore,
                                             value,
                                             onChange,
                                             maxCount = 1,
                                             maxSize = IMAGE_MAX_SIZE,
                                             ...props
                                         }) {
    const formatedAccepts = FILE_TYPES.format(accept)
    const {isDesktop} = useBreakPoints()
    const {
        handleBeforeUpload,
        fileList,onRemove
    } = useUpload({
        accept: formatedAccepts,
        onChange: onChange,
        maxCount: maxCount
    });

    const HAS_IMAGE = !!fileList[0]?.preview

    function handleDelete(e){
        e.stopPropagation()
        onRemove()
    }

    return (

        <ImgCrop
            modalTitle={'Cortar imagem'} showGrid showReset
            resetText={'Resetar imagem'}>
            <StyledUpload

                customRequest={null}
                maxCount={maxCount}
                beforeUpload={handleBeforeUpload}
                accept={formatedAccepts}
                showUploadList={false}
                // onChange={handleChange} // não usar o onchange junto com o ImgCrop pois ao retornar False no beforeUpload não atualiza o onchange com a imagem cortada.
            >
                <Container isDesktop={isDesktop} hasImage={HAS_IMAGE}>
                    {HAS_IMAGE && <ImagePreview src={fileList[0]?.preview}/>}
                    {HAS_IMAGE ? (<IconContainer className={'delete'} onClick={handleDelete}>
                        <PrimeIcon iconName={'pi-trash'}/>
                    </IconContainer>) : (
                        <IconContainer>
                            <PrimeIcon iconName={'pi-camera'}/>
                        </IconContainer>
                    )}
                </Container>
            </StyledUpload>
        </ImgCrop>


    );


}