import React from 'react'
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Layout} from "antd";
import Footer from "../components/layout/Footer";
import styled from 'styled-components'
import {secondary} from "../utils/Colors";
import useBreakPoints from "../hooks/useBreakPoints"

const StyledLayout = styled(Layout)`
    background-color: ${secondary};
    min-height: 100vh;
`

const StyledContent = styled(Layout.Content)`
    padding: ${({isPhone}) => isPhone ? '16px' : '70px'};

`


export default function LayoutLoginSystem() {
    const {isPhone} = useBreakPoints()
    const navigate = useNavigate()


    return (

            <StyledLayout>

                <StyledContent className={'main-content'} isPhone={isPhone}>
                    <Outlet/>
                </StyledContent>

                <Footer/>
            </StyledLayout>)
}