import React from 'react';
import { Switch} from "antd";

export default function SwitchField({
                                       ...props
                                   }) {

    return (
        <Switch {...props}/>
    )
}