import React, {useState} from 'react';
import {
    Row, Col, Card, Typography, Space,
} from "antd";
import styled from 'styled-components'

import {useAuthUserContext} from "../../../context/AuthUserContext";
import PrimeIcon from "../../../components/other/PrimeIcon";
import {blue_gray_200, gray, gray_2, primary} from "../../../utils/Colors";
import {
    ReactComponent as CreatorInviteIcon
} from "../../../assests/imgs/create_invite1.svg"
import {Title} from "../../../components/other/GenericComponents";
import InviteCard from "../../../components/other/InviteCard";
import useDefaultGetRequest from "../../../hooks/useDefaultGetRequest";
import {PATHS_API} from "../../../constans/PathsApi";
import Loading from "../../../components/feedback/Loading";
import InviteInfo from "../../../components/other/InviteInfo";
import CheckInvite from "../../../components/other/CheckInvite";
import Button from "../../../components/button/Button";
import ButtonInvites from "./components/ButtonInvites";

const StyledCard = styled(Card)`

    .ant-card-body {
        padding: 24px 24px;

    }
`
const ShortInfo = styled.span`
    font-size: 12px;
    line-height: 12px;
    color: ${primary};
`


export default function PageCreatorInvites() {
    const {user} = useAuthUserContext()
    const [creator, setCreator] = useState()
    const [member, setMember] = useState()
    const {
        isLoading,
    } = useDefaultGetRequest({
        url: PATHS_API.CREATOR_INVITES,
        onOk: (data) => {
            setCreator(data[0])
            setMember(data[1])
        }
    })
    function isChecked(value, limit){
        return value >= limit
    }

    return (
        <Row gutter={[22, 22]}>
            {isLoading ? <Col xs={24}><Loading/></Col> : (
                <>
                    <Col xs={24} lg={14} xl={12}>
                        <StyledCard style={{height: '100%'}}>
                            <Row gutter={[22, 22]}>
                                <Col xs={24} lg={10} xl={12}>
                                    <CreatorInviteIcon
                                        style={{width: '100%'}}/>
                                </Col>
                                <Col xs={24} lg={14} xl={12}>
                                    <Title>Gestão de convites</Title>
                                    <Typography.Text type={'secondary'}>
                                        Amplie suas vantagens ao começar a
                                        utilizar
                                        o <strong>FunForFan</strong> convidando
                                        outros criadores e assinantes à
                                        realizarem
                                        seus
                                        pré-cadastros em nossa plataforma.
                                    </Typography.Text>
                                </Col>
                                <Col xs={24}>
                                    <Title>Convide criadores</Title>
                                    <InviteCard url={creator?.link}
                                                title={'Envie o link para criadores'}
                                                amount={creator?.available_invites}/>
                                </Col>
                                <Col xs={24}>
                                    <Title>Convide fãs</Title>
                                    <InviteCard url={member?.link}
                                                title={'Envie o link para fãs'}
                                                amount={member?.available_invites}/>
                                    <ShortInfo>
                                        As metas de conversão serão contabilizadas após o lançamento oficial da plataforma
                                    </ShortInfo>
                                </Col>
                            </Row>

                        </StyledCard>
                    </Col>
                    <Col xs={24} lg={10} xl={12}>
                        <Row gutter={[22, 22]} style={{minHeight: '100%'}}>
                            <Col xs={24}>
                                <StyledCard>
                                    <Row gutter={[20,20]}>
                                        <Col xs={24} sm={12} lg={24} xl={12}>
                                            <Title style={{whiteSpace: 'nowrap'}}>Performace de criadores</Title>
                                            <InviteInfo amount={creator?.views} title={'Cliques no convite'}/>
                                            <InviteInfo amount={creator?.registrations} title={'cadastros realizados'}/>
                                            <InviteInfo amount={creator?.conversion_rate} title={'% de taxa de conversão'}/>
                                            <ButtonInvites category={creator?.category} title={'Cadastro de criadores'}/>
                                        </Col >
                                        <Col xs={24} sm={12} lg={24} xl={12}>
                                            <CheckInvite checked={isChecked(creator?.conversion_rate, 10)} title={'10 criadores cadastrados'} subtitle={<><strong>-1%</strong> de comissão por 6 meses</>}/>
                                            <CheckInvite checked={isChecked(creator?.conversion_rate, 20)} title={'20 criadores cadastrados'} subtitle={<><strong>-2%</strong> de comissão por 6 meses</>}/>
                                            <CheckInvite checked={isChecked(creator?.conversion_rate, 30)} title={'30 criadores cadastrados'} subtitle={<><strong>-3%</strong> de comissão por 6 meses</>}/>
                                            <CheckInvite checked={isChecked(creator?.conversion_rate, 40)} title={'40 criadores cadastrados'} subtitle={<><strong>-4%</strong> de comissão por 6 meses</>}/>
                                            <CheckInvite checked={isChecked(creator?.conversion_rate, 50)} title={'50 criadores cadastrados'} subtitle={<strong>2000 moedas F4F!</strong>}/>
                                        </Col>
                                    </Row>
                                </StyledCard>
                            </Col>
                            <Col xs={24} >
                                <StyledCard  style={{height: '100%'}}>
                                    <Row gutter={[20,20]}>
                                        <Col xs={24} sm={12} lg={24} xl={12}>
                                            <Title style={{whiteSpace: 'nowrap'}}>Performance fãs</Title>
                                            <InviteInfo amount={member?.views} title={'Cliques no convite'}/>
                                            <InviteInfo amount={member?.registrations} title={'cadastros realizados'}/>
                                            <InviteInfo amount={member?.conversion_rate} title={'% de taxa de conversão'}/>
                                            <InviteInfo amount={0} title={'compras realizadas(falta)'}/>
                                            <ButtonInvites category={member?.category} title={'Cadastro de membros'}/>
                                        </Col >
                                        <Col xs={24} sm={12} lg={24} xl={12}>
                                            <CheckInvite checked={isChecked(member?.conversion_rate, 50)} title={'50 compras realizadas'} subtitle={<><strong>-1%</strong> de comissão por 6 meses</>}/>
                                            <CheckInvite checked={isChecked(member?.conversion_rate, 100)} title={'100 compras realizadas'} subtitle={<><strong>-2%</strong> de comissão por 6 meses</>}/>
                                            <CheckInvite checked={isChecked(member?.conversion_rate, 150)} title={'150 compras realizadas'} subtitle={<><strong>-3%</strong> de comissão por 6 meses</>}/>
                                            <CheckInvite checked={isChecked(member?.conversion_rate, 200)} title={'200 compras realizadas'} subtitle={<><strong>-4%</strong> de comissão por 6 meses</>}/>
                                            <CheckInvite checked={isChecked(member?.conversion_rate, 250)} title={'250 compras realizadas'} subtitle={<strong>2000 moedas F4F!</strong>}/>
                                        </Col>
                                    </Row>
                                </StyledCard></Col>
                        </Row>
                    </Col>
                </>
            )}


        </Row>

    )
}
