import React from 'react';
import styled from 'styled-components'
import { Spin } from 'antd';
const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: min-content;
  justify-content: center;
  align-items: center;
`

function Loading({size='large',...props}) {
    return (
        <LoadingContainer  {...props}>
            <Spin size={size}/>
        </LoadingContainer>
    );
}

export default Loading;
