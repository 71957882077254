import React from 'react'
import RoutesSystem from "./routes/Routes";
import {
    BrowserRouter,
    createBrowserRouter, RouterProvider,
    ScrollRestoration
} from "react-router-dom";
import {ConfigProvider} from "antd";
import {primary} from "./utils/Colors";
import 'primeicons/primeicons.css';
import GlobalStyle from "./components/other/GlobalStyle";
import AppContenxt from "./context/AppContenxt";
import {locale} from "dayjs";
import ptBR from 'antd/locale/pt_BR'
import DevTools from "./components/other/DevTools";
import {DEBUG} from "./settings";

const THEME = {
    token: {
        colorPrimary: primary,
    },
    components: {
        Button: {
            colorPrimary: primary,
        },
    }
}

const router = createBrowserRouter([
    {path: "*", element: <Root/>},
]);

export default function App() {
    return <RouterProvider router={router}/>;
}

function Root() {
    locale('pt-br')
    return (
        <>
            <ConfigProvider theme={THEME} locale={ptBR}>

                <GlobalStyle/>
                {DEBUG && <DevTools/>}
                <AppContenxt>
                    <RoutesSystem/>
                </AppContenxt>
                <ScrollRestoration/>
            </ConfigProvider>
        </>
    );
}
