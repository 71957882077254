import React from 'react';
import styled from 'styled-components';
import {primary} from "../../utils/Colors";
import {NavLink} from "react-router-dom";
import PrimeIcon from "../other/PrimeIcon";
import {Space} from "antd";


const SOCIAL_MEDIA = [
    {
        link: '',//todo falta adicionar link
        icon: <PrimeIcon iconName={'pi-instagram'}/>
    },
    {
        link: '',
        icon: <PrimeIcon iconName={'pi-twitter'}/>
    },
    {
        link: '',
        icon: <PrimeIcon iconName={'pi-envelope'}/>
    },
    {
        link: '',
        icon: <PrimeIcon iconName={'pi-question-circle'}/>
    }

]


const SocialLink = styled(NavLink)`
    padding: 8px;
    border: 1px solid transparent;

    &:hover {
        border-radius: 4px;
        border: 1px solid ${primary};
    }
`

export default function SocialMedias() {

    return (<Space size={8} >
        {SOCIAL_MEDIA.map(({link, icon}, index) => (
            <SocialLink to={link} key={`social_media_${index}`}>
                {icon}
            </SocialLink>
        ))}
    </Space>)
}

