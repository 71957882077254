import React from 'react';
import FormItem from "./FormItem";
import DatePickerField from "../DatePickerField";

export default function DatePickerFormField({form, ...props}) {

    return (
        <FormItem {...form}>

            < DatePickerField
                {...props}
            />
        </FormItem>
    )
}