import React from 'react';
import InputField from "../InputField";
import FormItem from "./FormItem";

export default function InputFormField({form, ...props}) {

    return (
        <FormItem {...form}>

            < InputField
                name={form.name}
                {...props}
            />
        </FormItem>
    )
}