import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {PATHS_ROUTES} from "../constans/PathsRoutes";
import {useAuthUserContext} from "../context/AuthUserContext";

export const PublicRoutes = (props) => {
    const {hasAutheticatedUser} = useAuthUserContext()

    return hasAutheticatedUser() ? <Navigate to={PATHS_ROUTES.HOME}/>: <Outlet/>


}
