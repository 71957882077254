import React, {useEffect, useRef, useState} from 'react';
import {Select, Spin} from "antd";
import InputPrefixIcon from "./InputPrefixIcon";
import useDefaultGetRequest from "../../hooks/useDefaultGetRequest";
import {generateSelectOptions} from "../../utils/Utils";
import Divider from "../other/Divider";
import InputField from "./InputField";
import PrimeIcon from "../other/PrimeIcon";
import styled from 'styled-components';
import {primary} from "../../utils/Colors";

const StyledInputField = styled(InputField)`
    border: 2px solid ${primary} !important;
`

const StyledSelect = styled(Select)`
    ${({hasIcon}) => {
        if (hasIcon) {
            return `
                max-width: calc(100% - 40px);
            `
        }
    }}

`

export default function SelectField({
                                        addonBefore,
                                        key_label, key_value = 'id',
                                        value,
                                        mode = null,
                                        request = null,
                                        options,
                                        labelRender,
                                        ...props
                                    }) {

    //todo ajustar o search quando  nao tiver o request


    const [_options, _setOptions] = useState([])
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')
    // const [isLoadingInitialValue, setIsLoadingInitialValue] = useState(true)
    const [selectedOptions, setSelectedOptions] = useState([])
    const [searchReaquest, setSearchRequest] = useState('')
    // const selectedOptions = useRef([])// usado para manter a lista de objetos original caso o usuario realize algum filtro e mude as opçoes
    const searchTimeoutRef = useRef()
    const searchInputRef = useRef()
    const initialValue = useRef(true)
    const IS_MULTIPLE_SELECT = !!mode

    const {
        data,
        isLoading,
        nextPage
    } = useDefaultGetRequest({
        url: request?.url,
        params: {
            search: searchReaquest,
            ...request?.params
        },

        makeRequest: !!request && open,
        dependence: [request?.url, searchReaquest]
    })


    // useEffect(() => {
    //     if(selectedOptions.length > 0) {
    //         console.log('chamou')
    //         setIsLoadingInitialValue(false)
    //     }
    // }, [selectedOptions]);

    useEffect(() => {
        if (value) {

            if (_options.length > 0) {
                saveSelectedOptions()
            } else if (selectedOptions.length === 0 && initialValue.current) {
                parseInitialValue()
            }

        } else {
            clearSelectedOptions()
        }
        // eslint-disable-next-line
    }, [value, _options]);


    function clearSelectedOptions() {
        setSelectedOptions([])
    }


    function saveSelectedOptions() {
        /**
         * usado para salvar no selectedOptions, as opções selectionada, isso evita que perca informação caso as opções mudem(paginação, search etc)
         */

        if (IS_MULTIPLE_SELECT) {

            const newSelectedsValues = []

            value.forEach((itemSelected) => {
                const valueNotSaved = !selectedOptions.some((item) => item.value === itemSelected)
                if (valueNotSaved) {
                    const optionSelected = _options.find((item) => item.value === itemSelected)

                    newSelectedsValues.push(optionSelected)// salva o novo valor no formato das opções
                }
            })

           setSelectedOptions( [...selectedOptions, ...newSelectedsValues])
        } else {
            const valueNotSaved = !selectedOptions.some((item) => item.value === value)
            if (valueNotSaved) {
                const option = _options.find((item) => item.value === value)
                setSelectedOptions([option])

            }
        }
    }


    function parseInitialValue() {
        let valuesFormated
        initialValue.current = false
        if (IS_MULTIPLE_SELECT) {

            valuesFormated = generateSelectOptions(value, key_label, key_value) //transforma o valor inicial no formato das opções do select
            const values = valuesFormated.map(({value}) => value)
            props.onChange(values)
        } else {
            valuesFormated = generateSelectOptions([value], key_label, key_value)
            props.onChange(valuesFormated[0].value)

        }
        _setOptions(valuesFormated)//para o valor inicial como opção ja formatado
        setSelectedOptions( valuesFormated)// passa o valor formatado inicial formatado caso as options mude.
    }

    useEffect(() => {
        if (request && data) {
            generateOptions(data)
        } else if (options) {
            generateOptions(options)
        }
        // eslint-disable-next-line
    }, [data, options]);


    function generateOptions(data) {

        _setOptions(generateSelectOptions(data, key_label, key_value))

    }

    function handleSearch({target}) {

        if (!target) {
            return
        }
        setSearch(target.value)
        const delay = 1000

        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current)
        }
        searchTimeoutRef.current = setTimeout(() => {
            setSearchRequest(target.value)
        }, delay)
    }

    function handleClear() {
        setSearchRequest('')
        setSearch('')
    }

    function handleInfinitScroll({target}) {
        const triggerInPixel = 20
        const isScrollBottom = target.scrollTop + target.offsetHeight >= target.scrollHeight - triggerInPixel
        if (!isLoading > 0 && isScrollBottom) {
            nextPage()
        }
    }


    useEffect(() => {
        if (open && searchInputRef.current) {

            setTimeout(() => searchInputRef?.current?.focus({cursor: 'end'}), 1000)
        } else {
            handleClear()
        }
    }, [open])
    return (
        <InputPrefixIcon addonBefore={addonBefore}>
            <StyledSelect
                hasIcon={!!addonBefore}
                // onSearch={handleSearch}
                valeu={value}
                showSearch={false}
                onPopupScroll={handleInfinitScroll}
                filterOption={true}
                onClear={handleClear}
                allowClear
                onDropdownVisibleChange={(open) => {

                    setOpen(open)

                }}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        {isLoading && <Spin size={'small'}
                                            style={{width: '100%'}}/>}

                        {/*{isLoading ? <Spin size={'small'}*/}
                        {/*                 style={{width: '100%'}}/> : _options.length === 0 ?*/}
                        {/*  <Empty/> : null}*/}
                        <Divider marginTop={8} marginBottom={8}/>
                        <StyledInputField innerRef={searchInputRef.current}
                                          value={search}
                                          addonBefore={<PrimeIcon
                                              iconName={'pi-search'}/>}
                                          onChange={handleSearch}
                                          allowClear/>
                    </>
                )}
                labelRender={(props) => {
                    if (labelRender) {
                        return labelRender?.(props, selectedOptions)
                    }
                    return props.label

                }}
                options={_options} value={value} mode={mode} {...props}/>
        </InputPrefixIcon>


    )
}

