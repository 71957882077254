import React from 'react';
import InputField from "../InputField";
import FormItem from "./FormItem";
import ImageUploadField from "../ImageUploadField";

export default function ImageUploadFormField({form, ...props}) {
    return (
        <FormItem {...form}>

            < ImageUploadField
                {...props}
            />
        </FormItem>
    )
}