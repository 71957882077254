import {useEffect, useRef, useState} from "react";
import api from "../services/api";
import {BuildFieldErrorsDict} from "../utils/Utils";

export default function useDefaultGetRequest({
                                                 url,
                                                 setData,
                                                 onOk,
                                                 onError,
                                                 params,
                                                 clearData,
                                                 makeRequest = true,
                                                 // hasPagination = false,
                                                 dataDefault = '',
                                                 dependence = []
                                             }) {

    const [isLoading, setIsLoading] = useState(false)
    const [_data, _setData] = useState(dataDefault)
    const [response, setResponse] = useState({})
    // const nextUrl = useRef()

    useEffect(() => {
        if (makeRequest) fetchData()
    }, [...dependence, makeRequest])
    useEffect(() => {
        if (clearData) {
            _setData(dataDefault)
            setIsLoading(false)
            setResponse({})
        }
    }, [clearData])

    const fetchData = async (nextURL) => {
        try {
            setIsLoading(true)
            const response = await api.get(nextURL || url, {params: params});

            let dataRequest = response.data
            if (response.data.hasOwnProperty('results')) {
                if (nextURL) {
                    dataRequest = [..._data, ...response.data.results]
                } else {
                    dataRequest = response.data.results
                }
            }
            setResponse(response)
            _setData(dataRequest)
            setData?.(dataRequest)
            onOk?.(dataRequest)
        } catch (error) {
            if (onError) {
                onError(error)
            } else {
                BuildFieldErrorsDict(error, null, false)
            }
        } finally {
            setIsLoading(false);
        }
    }

    function nextPage() {
        if (response.data.next) {
            fetchData(response.data.next)
        }

    }

    return {
        data: _data,
        response,
        nextPage,
        isLoading
    }
}