let endpoint;
let debug;
let enviroment_name;


if (process.env.REACT_APP_ENV === 'prod') {
    endpoint = process.env.REACT_APP_PROD_BASE_URL
    enviroment_name = 'PROD';
    debug = false

} else if (process.env.REACT_APP_ENV === 'homo') {
    endpoint = process.env.REACT_APP_HOMO_BASE_URL
    enviroment_name = 'HOMOLOGATION';
    debug = false

}  else {
    endpoint = process.env.REACT_APP_LOCAL_BASE_URL
    enviroment_name = 'LOCAL';
    debug = true
}


export const BASE_ENDPOINT = endpoint;
export const DEBUG = debug;
export const ENVIROMENT_NAME = enviroment_name;
