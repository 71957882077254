import React from 'react';
import {Link,} from "react-router-dom";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import {ReactComponent as LogoFFF} from '../../../assests/imgs/logo_fff.svg'
import {
    Typography,
    Layout,
    Flex, Card
} from "antd";
import styled from 'styled-components'
import Button from "../../../components/button/Button";
import useBreakPoints from "../../../hooks/useBreakPoints";
import Footer from "../../../components/layout/Footer";
import {secondary, secondary_2} from "../../../utils/Colors";
import LimitContent from "../../../components/layout/LimitContent";

const StyledLayout = styled(Layout)`
    background-color: ${secondary_2};
    //background: white;
    min-height: 100vh;
`

const StyledContent = styled(Layout.Content)`


    padding: 16px;
        //padding: ${({isDesktop}) => isDesktop ? '93px 150px' : '16px'};

`
const StyledCard = styled(Card)`
        
   
    .ant-card-body {
        padding: 48px 48px;
*{
     color: #646464 ;
}
    }
`

const Container = styled.div`
    padding: 8px 48px;
    background-color: ${secondary};
    position: sticky;
    top: 0;
    z-index: 99;
    box-shadow: ${({isScroll}) => isScroll ? ' 0px 0px 5px 1px rgba(0, 0, 0, 0.3)' : 'none'};
`


const StyledLogoFFF = styled(LogoFFF)`
    width: min-content;
    height: 48px;
`


export default function PageTermsOfService() {
    const {isDesktop} = useBreakPoints()


    return (<StyledLayout>
        <ActionBar/>

        <StyledContent isDesktop={isDesktop}>
            <LimitContent>
                <StyledCard>
                    <Typography.Title level={3}>
                        teste
                    </Typography.Title>
                    <Typography.Paragraph>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's
                        standard dummy text ever since the 1500s, when an
                        unknown
                        printer took a galley of type and scrambled it to make
                        a
                        type
                        specimen book. It has survived not only five centuries,
                        but
                        also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s
                        with
                        the
                        release of Letraset sheets containing Lorem Ipsum
                        passages,
                        and
                        more recently with desktop publishing software like
                        Aldus
                        PageMaker including versions of Lorem Ipsum.
                    </Typography.Paragraph>


                </StyledCard>
            </LimitContent>
        </StyledContent>

        <Footer/>
    </StyledLayout>)
}


function ActionBar() {
    return (<Container>
        <LimitContent>
            <Flex justify={'space-between'}>

                <Link to={PATHS_ROUTES.HOME}>
                    <StyledLogoFFF/>
                </Link>
                <Link to={PATHS_ROUTES.HOME}>
                    <Button>

                        Voltar
                    </Button>
                </Link>


            </Flex>
        </LimitContent>
        {/*</NavLink>*/}
    </Container>)
}