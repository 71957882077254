export const text_color = 'rgba(0, 0, 0, 0.85)';
// export const red = '#BE39D9';
// export const red = '#895498';
export const primary = '#7152f6';
export const secondary = '#E0E7FF';
export const secondary_2 = '#EDF1FC';
export const gray = '#6B7280';//todo validar um nome melhor depois
export const gray_2 = '#646464';//todo validar um nome melhor depois
export const green = '#34A853';//todo validar um nome melhor depois
export const red = '#8c559b';


export const blue_gray_200 = '#E2E8F0';

export const blue_1 = '#E6F7FF'
export const blue_2 = '#bae7ff'
export const blue_3 = '#91d5ff'
export const blue_4 = '#69c0ff'
export const blue_5 = '#40a9ff'
export const blue_6 = '#1890ff'
export const blue_7 = '#096dd9'
export const blue_8 = '#0050b3'
export const blue_9 = '#003a8c'
export const blue_10 = '#002766'


export const neutral_1 = '#ffffff'
export const neutral_2 = '#fafafa'
export const neutral_3 = '#f5f5f5'
export const neutral_4 = '#f0f0f0'
export const neutral_5 = '#d9d9d9'
export const neutral_6 = '#bfbfbf'
export const neutral_7 = '#8c8c8c'
export const neutral_8 = '#595959'
export const neutral_9 = '#434343'
export const neutral_10 = '#262626'
export const neutral_11 = '#1f1f1f'
export const neutral_12 = '#141414'
export const neutral_13 = '#000000'



export const green_1 = '#f6ffed'
export const green_2 = '#d9f7be'
export const green_3 = '#b7eb8f'
export const green_4 = '#95de64'
export const green_5 = '#73d13d'
export const green_6 = '#52c41a'
export const green_7 = '#389e0d'
export const green_8 = '#237804'
export const green_9 = '#135200'
export const green_10 = '#092b00'

export const red_1 = '#fff1f0'
export const red_2 = '#ffccc7'
export const red_3 = '#ffa39e'
export const red_4 = '#ff7875'
export const red_5 = '#ff4d4f'
export const red_6 = '#f5222d'
export const red_7 = '#cf1322'
export const red_8 = '#a8071a'
export const red_9 = '#820014'
export const red_10 = '#5c0011'



