import React from 'react';

import {Space, Typography} from "antd";
import styled from 'styled-components'

const Image = styled.img`
    min-width: 16px;

`

export default function FlagOptionSelect({
                                             src,
                                             title
                                         }) {


    return (
        <Space>
            <Image src={src}/>
            <Typography.Text>{title}</Typography.Text>
        </Space>
    )
}