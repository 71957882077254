import React from "react";
import styled from 'styled-components';
import {primary} from "../../utils/Colors";

const Container = styled.span`
    color: ${primary};
    
`

export default function PrimeIcon({iconName, ...props}){
    return (
        <Container {...props}  >
            <i className={`pi ${iconName}`}/>
        </Container>)
}