import React, {useEffect, useMemo} from 'react';
import {useNavigate} from "react-router-dom";
import {Form as FormAntd, Row, Col, Card, Flex, Space, Checkbox} from "antd";
import styled from 'styled-components'
import Form from "../../../components/data_entry/form/Form";
import InputFormField
    from "../../../components/data_entry/form/InputFormField";
import PrimeIcon from "../../../components/other/PrimeIcon";

import {BuildFieldErrorsDict} from "../../../utils/Utils";

import {useAuthUserContext} from "../../../context/AuthUserContext";
import {Title} from "../../../components/other/GenericComponents";
import useBreakPoints from "../../../hooks/useBreakPoints";
import DatePickerFormField
    from "../../../components/data_entry/form/DatePickerFormField";
import SelectFormField
    from "../../../components/data_entry/form/SelectFormField";
import FlagOptionSelect from "../../../components/other/FlagOptionSelect";
import PhoneNumberFormField
    from "../../../components/data_entry/form/PhoneNumberFormField";

import TextAreaFormField
    from "../../../components/data_entry/form/TextAreaFormField";
import ImageUploadFormField
    from "../../../components/data_entry/form/ImageUploadFormField";
import Button from "../../../components/button/Button";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import {PATHS_API} from "../../../constans/PathsApi";
import api from "../../../services/api";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import dayjs from "dayjs";


const StyledCard = styled(Card)`

    .ant-card-body {
        padding: 48px 48px;

    }
`


const FORM_NAMES = {
    IMAGE: 'image',
    USERNAME: 'username',
    FIRST_NAME: 'first_name',
    BORN_AT: 'born_at',
    CITY: 'city',
    STATE: 'state',
    COUNTRY: 'country',
    PHONE: 'fone',
    BIO: 'bio',

    SOCIAL_MEDIA: {
        name: 'social_networks',
        TIKTOK: 'tiktok',
        TWITTER: 'twitter',
        INSTAGRAM: 'instagram',
        WEBSITE: 'website',

    }
}


export default function PageCreateProfile() {
    const navigate = useNavigate()
    const [form] = FormAntd.useForm()
    const [isLoading, setIsLoading] = React.useState(false)
    const {user, refreshUser} = useAuthUserContext()


    async function handleSubmit() {
        try {
            setIsLoading(true)
            const data = await getValuesForm()
            const response = await api.post(PATHS_API.PROFILE, data);
            navigate(PATHS_ROUTES.HOME)
            refreshUser()
            ToastNotification('Sucesso')
        } catch (error) {
            // console.log(error.response.data)
            BuildFieldErrorsDict(error, form.setFields)
        } finally {
            setIsLoading(false);
        }
    }


    function generateSocialValue(social_networks) {
        const newSocialNetworks = []

        Object.entries(social_networks).forEach(([key, value]) => {
            if (value) {
                newSocialNetworks.push({
                    channel: key,
                    username: value,
                })
            }
        })

        return newSocialNetworks
    }

    async function getValuesForm() {
        let data = await form.getFieldsValue()
        delete data[FORM_NAMES.IMAGE]//todo falta upload da imagem
        data['user'] = user.id
        data['social_networks'] = generateSocialValue(data['social_networks'])

        return data
    }


    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                [FORM_NAMES.FIRST_NAME]: user.first_name,
                [FORM_NAMES.USERNAME]: user.username,
            })
        }

    }, [user])

    return (<StyledCard>
        <Form form={form} onFinish={handleSubmit} disabled={isLoading}>
            <Row gutter={[20, 0]}>
                <Col xs={24}><Title>Continue o seu cadastro</Title></Col>
                <Col xs={24} lg={8} xl={6}>
                    <ImageUploadFormField
                        form={{
                            label: "Imagem(Ainda nao funciona)",
                            // required: true,
                            name: FORM_NAMES.IMAGE,
                        }}
                    />
                </Col>
                <Col xs={24} lg={8} xl={9}>
                    <Flex vertical>
                        <InputFormField
                            form={{
                                label: "Usuário",
                                required: true,
                                name: FORM_NAMES.USERNAME,
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-at'}/>}/>
                        <InputFormField
                            form={{
                                label: "Nome",
                                required: true,
                                name: FORM_NAMES.FIRST_NAME,
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-user'}/>}/>
                        <DatePickerFormField
                            maxDate={dayjs()}
                            form={{
                                label: "Data de Nascimento",
                                required: true,
                                name: FORM_NAMES.BORN_AT,
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-calendar'}/>}/>
                        <SelectFormField
                            style={{maxWidth: 'auto'}}
                            request={{
                                url: 'countries',
                            }}
                            form={{
                                label: "País",
                                required: true,
                                name: FORM_NAMES.COUNTRY,
                            }}
                            key_label={'name'}
                            key_value={'code'}
                            labelRender={({value}, options) => {

                                const option = options.find(({value}) => value === value)
                                return <FlagOptionSelect
                                    src={option?.object.flag}
                                    title={option?.object.name}/>

                            }}
                            optionRender={({data: {object}, ...props}) => (
                                <FlagOptionSelect src={object.flag}
                                                  title={object.name}/>
                            )}
                            addonBefore={<PrimeIcon
                                iconName={'pi-map-marker'}/>}/>

                        <InputFormField
                            form={{
                                label: "Estado",
                                required: true,
                                name: FORM_NAMES.STATE,
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-map-marker'}/>}/>

                        <InputFormField
                            form={{
                                label: "Cidade",
                                required: true,
                                name: FORM_NAMES.CITY,
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-map-marker'}/>}/>


                    </Flex>
                </Col>
                <Col xs={24} lg={8} xl={9}>
                    <Flex vertical>
                        <PhoneNumberFormField
                            form={{
                                label: "Telefone",
                                required: true,
                                name: FORM_NAMES.PHONE,
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-phone'}/>}/>


                        <InputFormField
                            form={{
                                label: "TikTok",
                                // required: true,
                                name: [FORM_NAMES.SOCIAL_MEDIA.name, FORM_NAMES.SOCIAL_MEDIA.TIKTOK],

                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-tiktok'}/>}/>
                        <InputFormField
                            form={{
                                label: "X",
                                // required: true,
                                name: [FORM_NAMES.SOCIAL_MEDIA.name, FORM_NAMES.SOCIAL_MEDIA.TWITTER],
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-twitter'}/>}/>
                        <InputFormField
                            form={{
                                label: "Instagram",
                                // required: true,
                                name: [FORM_NAMES.SOCIAL_MEDIA.name, FORM_NAMES.SOCIAL_MEDIA.INSTAGRAM],
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-instagram'}/>}/>
                        <InputFormField
                            form={{
                                label: "Site",
                                // required: true,
                                name: [FORM_NAMES.SOCIAL_MEDIA.name, FORM_NAMES.SOCIAL_MEDIA.WEBSITE],
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-globe'}/>}/>
                        <TextAreaFormField
                               style={{height: 130}}
                            form={{
                                label: "Bio",
                                required: true,
                                name: FORM_NAMES.BIO,
                            }}
                        />


                        <Button block onClick={form.submit}
                                loading={isLoading}>Salvar</Button>
                    </Flex>
                </Col>

            </Row>
        </Form>
    </StyledCard>)
}


