import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {gray, primary, secondary} from "../../utils/Colors";
import useBreakPoints from "../../hooks/useBreakPoints";
import {Avatar, Dropdown, Flex, Space, Typography} from "antd";
import {Link, NavLink} from "react-router-dom";
import {PATHS_ROUTES} from "../../constans/PathsRoutes";
import {ReactComponent as LogoFFF} from "../../assests/imgs/logo_fff.svg"
import {useAuthUserContext} from "../../context/AuthUserContext";
import LimitContent from "./LimitContent";

const Container = styled.div`
    padding: 8px 48px;
    background-color: ${secondary};
    position: sticky;
    top: 0;
    z-index: 99;
    box-shadow: ${({isScroll}) => isScroll ? ' 0px 0px 5px 1px rgba(0, 0, 0, 0.3)' : 'none'};
`

const StyledAvatar = styled(Avatar)`
    border: 2px solid ${primary};

`


const StyledLogoFFF = styled(LogoFFF)`
    width: min-content;
    height: 48px;
`

const Text = styled.span`
    font-size: 12px;
    color: ${gray};
`

export default function ActionBar() {
    const {isPhone, isTablet} = useBreakPoints()
    const {logout, user} = useAuthUserContext()
    const [isScroll, setIsScroll] = useState(false)
    const actionBarRef = useRef()
    const SHOW_PROFILE_ITEM = !!user.profile || (user.invite_creator && !!user.profile_creator)

    const items = [
        {
            key: '1',
            label: <NavLink to={PATHS_ROUTES.PROFILE}>Perfil</NavLink>,
            hasPermission: SHOW_PROFILE_ITEM
        },
        {
            type: 'divider',
            hasPermission: SHOW_PROFILE_ITEM
        },
        {
            key: '2',
            label: <div onClick={logout}>Sair</div>,
        },
    ]

    useEffect(() => {
        function addBorder() {
            setIsScroll(window.scrollY > actionBarRef?.current?.offsetHeight)
        }

        document.addEventListener('scroll', addBorder)

        return () => {
            document.removeEventListener('scroll', addBorder)
        }

    }, []);

    return (<Container ref={actionBarRef} isScroll={isScroll}>
        {/*<NavLink to={PATHS_ROUTES.HOME}>*/}
        <LimitContent>
            <Flex justify={'space-between'}>

                <Link to={PATHS_ROUTES.HOME}>
                    <StyledLogoFFF/>
                </Link>

                <Dropdown
                    menu={{items: items.filter(({hasPermission}) => hasPermission !== false)}}>
                    <Space direction={'horizontal'} align={'center'}
                           style={{cursor: 'pointer'}}>
                        <Text type={'secondary'}>
                            {user.username}
                        </Text>
                        <StyledAvatar
                            src={'https://files.tecnoblog.net/wp-content/uploads/2022/09/stable-diffusion-imagem.jpg'}>Diogo</StyledAvatar>

                    </Space>

                </Dropdown>
            </Flex>
        </LimitContent>
        {/*</NavLink>*/}
    </Container>)
}

