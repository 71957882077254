import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {isAuthenticated} from '../utils/Auth';
import {PATHS_ROUTES} from "../constans/PathsRoutes";
import useQuery from "../hooks/useQuery";
import {useEffect, useState} from "react";
import {useAuthUserContext} from "../context/AuthUserContext";
import {ToastNotification} from "../components/feedback/ToastNotification";
import {usePermissionsContext} from "../context/PermissionsContext";
import Loading from "../components/feedback/Loading";

const restrictedRoutes = [ //rotas de acesso unico
    PATHS_ROUTES.AUTH_CONFIRM_EMAIL,
    PATHS_ROUTES.CREATE_PROFILE,
    PATHS_ROUTES.CREATE_PROFILE_CREATOR,
]

export const AuthenticatedRoutes = (props) => {

    const location = useLocation()
    const [isLoadingValidation, setIsLoadingValidation] = useState(true)
    const querys = useQuery();
    const navigate = useNavigate()
    const {user} = useAuthUserContext()
    const {hasAutheticatedUser, isLoadingUser} = useAuthUserContext()

    useEffect(() => {
        if (!isLoadingUser && !hasAutheticatedUser()) {
            const pathname = location.pathname
            querys.set('next', pathname)
            navigate({pathname: PATHS_ROUTES.LOGIN, search: querys.toString()})
        }
    }, [isLoadingUser])

    useEffect(() => {
        if (user) {
            validateDependencies()
        }
    }, [user]);

    function validateDependencies() {
        setIsLoadingValidation(true)
        const pathname = location.pathname
        const EMAIL_NOT_CONFIRMED = !user.email_confirmed
        const HAS_NO_PROFILE = !user.profile
        const MUST_CREATE_CREATOR_PROFILE = user.invite_creator && !user.profile_creator

        if (EMAIL_NOT_CONFIRMED) {
            navigate(PATHS_ROUTES.AUTH_CONFIRM_EMAIL)
        } else if (HAS_NO_PROFILE) {
            ToastNotification('Precisamos de mais dados para finalizar o seu cadastro', 'warning')
            navigate(PATHS_ROUTES.CREATE_PROFILE)
        } else if (MUST_CREATE_CREATOR_PROFILE) {
            ToastNotification('Precisamos de mais dados para finalizar o seu cadastro de criador de conteúdo', 'warning')
            navigate(PATHS_ROUTES.CREATE_PROFILE_CREATOR)
        } else if (restrictedRoutes.includes(pathname)) {
            navigate(PATHS_ROUTES.HOME)
        }
        setIsLoadingValidation(false)

    }

    if (isLoadingUser || isLoadingValidation) return null //todo talvez criar uma tela de loading inicial

    return hasAutheticatedUser() ? <Outlet/> :
        <Navigate to={PATHS_ROUTES.LOGIN}/>


}
