import React from 'react';
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

export default function useBreakPoints() {
    const {xs, sm, md, lg, xl, xxl} = useBreakpoint()
    const [breaks, setBreaks] = React.useState({
        isPhone: null,
        isTablet: null,
        isDesktop: null
    })

    React.useEffect(() => {
        let _isDesktop = lg || xl || xxl
        let _isTablet = md && !_isDesktop
        let _isPhone = !_isTablet && !_isDesktop
        setBreaks({
            isPhone: _isPhone,
            isTablet: _isTablet,
            isDesktop: _isDesktop
        })

    }, [xs, sm, md, lg, xl, xxl])


    return {
        isXsSize: xs,
        isSmSize: sm,
        isMdSize: md,
        isLgSize: lg,
        isXlSize: xl,
        isXxlSize: xxl,
        isPhone: breaks.isPhone,
        isTablet: breaks.isTablet,
        isDesktop: breaks.isDesktop
    }
}