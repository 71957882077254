import React from 'react';
import {Space} from "antd";
import styled from 'styled-components'
import {blue_gray_200, red_5} from "../../utils/Colors";

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  0 11px;
    border: 1px solid #d9d9d9;
    border-right: none;
    border-radius: 6px 0px 0px 6px;
    background-color: ${blue_gray_200};
    max-width: 40px;
    min-width: 40px;
    .ant-form-item-has-error & {
        border: 1px solid ${red_5};
        border-right: none;
    }
    
    & + * {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`

export default function InputPrefixIcon({addonBefore, children}) {
    return (
        <Space.Compact block>
            {addonBefore && <IconContainer className={'input_icon'} >
                {addonBefore}
            </IconContainer>}
            {children}
        </Space.Compact>

    )
}