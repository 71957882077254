import React from 'react';
import {useNavigate,} from "react-router-dom";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import {ReactComponent as LogoFFF} from '../../../assests/imgs/logo_fff.svg'
import {Card, Result} from "antd";
import styled from 'styled-components'

import Button from "../../../components/button/Button";
import { secondary} from "../../../utils/Colors";
import {useAuthUserContext} from "../../../context/AuthUserContext";

const Container = styled.div`
    padding: 16px 70px;
    background-color: ${secondary};
`
const StyledLogoFFF = styled(LogoFFF)`
    margin: 0 auto 19px;
    width: 100%;
    //display: inline-block;
`
const StyledCard = styled(Card)`
    max-width: 508px;
    margin: 0 auto;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1);

    .ant-card-body {
        padding: 0px;

    }
`


export default function PageNotFound404() {
    const navigate = useNavigate()
    const {hasAutheticatedUser} = useAuthUserContext()
    function handleGoBack() {
        if (hasAutheticatedUser()) {
            navigate(PATHS_ROUTES.HOME)
        } else {
            navigate(PATHS_ROUTES.LOGIN)
        }
    }

    return (<Container>
        <StyledLogoFFF/>
        <StyledCard>
            <Result
                status="404"
                title="404"
                subTitle="Desculpe, Está pagina não foi encontrada."
                extra={<Button
                    onClick={handleGoBack}>Voltar</Button>}
            />
        </StyledCard>
    </Container>)
}