import React from 'react';
import InputField from "../InputField";
import FormItem from "./FormItem";
import TextAreaField from "../TextAreaField";

export default function TextAreaFormField({form, ...props}) {
    return (
        <FormItem {...form}>

            < TextAreaField
                {...props}
            />
        </FormItem>
    )
}