import React from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import {ReactComponent as LogoFFF} from '../../../assests/imgs/logo_fff.svg'
import { Form as FormAntd, Typography} from "antd";
import styled from 'styled-components'
import Button from "../../../components/button/Button";
import api from "../../../services/api";
import {BuildFieldErrorsDict} from "../../../utils/Utils";
import {PATHS_API} from "../../../constans/PathsApi";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import Divider from "../../../components/other/Divider";
import InputFormField
    from "../../../components/data_entry/form/InputFormField";
import PrimeIcon from "../../../components/other/PrimeIcon";
import Form from "../../../components/data_entry/form/Form";
import LoginCard from "../../../components/other/LoginCard";
import useBreakPoints from "../../../hooks/useBreakPoints";


const StyledLogoFFF = styled(LogoFFF)`
    margin: 0 auto 19px;
    width: 100%;
`

const Title = styled.h3`
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
`


const FORM_NAMES = {
    EMAIL: 'email',
}


export default function PageForgotPassword() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = React.useState()
    const [form] = FormAntd.useForm()
    const {isPhone} = useBreakPoints()

    async function handleSubmit(data) {
        try {
            setIsLoading(true)
            const response = await api.post(PATHS_API.FORGOT_PASSWORD, data);
            ToastNotification('Um email de recuperação foi enviado para seu email!')
            navigate(PATHS_ROUTES.LOGIN)
        } catch (error) {

            BuildFieldErrorsDict(error, form.setFields, false)
        } finally {
            setIsLoading(false);
        }
    }

    return (<>
        <StyledLogoFFF/>
        <LoginCard>
            <Title>
                Recuperação de senha
            </Title>
            <Divider marginTop={isPhone? 15:20} marginBottom={isPhone? 15: 21}/>
            <Typography.Text style={{
                maxWidth: 400,
                display: 'inline-block',
                textAlign: 'center',
                marginBottom: 32
            }}
                             type={'secondary'}>
                Para recuperar sua senha informe o seu e-mail de cadastro que
                enviaremos o link para a alteração da senha.
            </Typography.Text>
            <Form onFinish={handleSubmit} form={form}
                  style={{maxWidth: 377, margin: '0 auto'}}>
                <InputFormField
                    form={{
                        label: "E-mail",
                        required: true,
                        type: 'email',
                        name: FORM_NAMES.EMAIL,
                    }}
                    placeholder={'Digite seu e-mail'}
                    addonBefore={<PrimeIcon
                        iconName={'pi-envelope'}/>}/>
                <Button block style={{maxWidth: 376}} onClick={form.submit}
                        loading={isLoading}>
                    Enviar
                </Button>

                        <NavLink to={PATHS_ROUTES.LOGIN} style={{margin: '0 auto', textAlign: 'center', display: 'block', marginTop: 14}}>
                            <Typography.Text type={'secondary'}>Voltar ao login</Typography.Text></NavLink>

            </Form>

        </LoginCard>
    </>)
}