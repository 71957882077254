import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {PATHS_ROUTES} from "../constans/PathsRoutes";
import {usePermissionsContext} from "../context/PermissionsContext";

export const PermissionRoute = ({hasPermission, redirectPath=PATHS_ROUTES.HOME, children}) => {
    const {isLoadingPermissions}= usePermissionsContext()

    if(isLoadingPermissions) return null
    return hasPermission ? children : <Navigate to={redirectPath}/>


}
