import React, {useState, createContext, useEffect, useContext} from 'react';

import {useAuthUserContext} from "./AuthUserContext";

const PermissionsContext = createContext();

function PermissionsProvider({children}) {
    const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);
    const [hasCreatorPermission, setHasCreatorPermission] = useState(false);
    const {user} = useAuthUserContext()

    useEffect(() => {
        if(user){
            setIsLoadingPermissions(true)
            setHasCreatorPermission(user.invite_creator)
            setIsLoadingPermissions(false)
        }
    }, [user])



    return (
        <PermissionsContext.Provider
            value={{hasCreatorPermission, isLoadingPermissions}}>
            {children}
        </PermissionsContext.Provider>
    );
}

export {PermissionsProvider};

export function usePermissionsContext() {
    return useContext(PermissionsContext);
}
