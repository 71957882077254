import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import {PATHS_ROUTES} from "../constans/PathsRoutes";
import {AuthenticatedRoutes} from "./AuthenticatedRoutes";
import PageLogin from "../pages/PagesPublic/PageLogin/PageLogin";
import LayoutLoginSystem from "../layout/LayoutLoginSystem";
import PageInvite from "../pages/PagesPublic/PageInvite/PageInvite";
import PageConfirmEmail
    from "../pages/PagesPublic/PageConfirmEmail/PageConfirmEmail";
import PageCheckValidateEmail
    from "../pages/PagesPublic/PageCheckValidateEmail/PageCheckValidateEmail";
import PageNotFound404
    from "../pages/PagesPublic/PageNotFound404/PageNotFound404";
import PageForgotPassword
    from "../pages/PagesPublic/PageForgotPassword/PageForgotPassword";
import PageResetPassword
    from "../pages/PagesPublic/PageResetPassword/PageResetPassword";
import LayoutAuthSystem from "../layout/LayoutAuthSystem";
import PageCreateProfile
    from "../pages/PagesProtected/PageCreateProfile/PageCreateProfile";
import PageDeleteProfile
    from "../pages/PagesProtected/PageDeleteProfile/PageDeleteProfile";
import PageCreateProfileCreator
    from "../pages/PagesProtected/PageCreateProfileCreator/PageCreateProfileCreator";
import {PublicRoutes} from "./PublicRoutes";
import PageWelcome from "../pages/PagesProtected/PageWelcome/PageWelcome";
import PageLogout from "../pages/PagesPublic/PageLogout/PageLogout";
import PageCreatorInvites
    from "../pages/PagesProtected/PageCreatorInvites/PageCreatorInvites";
import PageInvalidInvite
    from "../pages/PagesPublic/PageInvalidInvite/PageInvalidInvite";
import {PermissionRoute} from "./PermissionRoute";
import {usePermissionsContext} from "../context/PermissionsContext";
import PageProfile from "../pages/PagesProtected/PageProfile/PageProfile";
import PageTermsOfService
    from "../pages/PagesPublic/PageTermsOfService/PageTermsOfService";


export default function RoutesSystem() {
    const {hasCreatorPermission} = usePermissionsContext()

    return (
        <Routes>
            //PUBLIC ROUTES
            <Route path={PATHS_ROUTES.TERMS_OF_SERVICE}
                   element={<PageTermsOfService/>}/>
            <Route element={<LayoutLoginSystem/>}>
                <Route path={PATHS_ROUTES.CHECK_VALIDATE_EMAIL}
                       element={<PageCheckValidateEmail/>}/>
                <Route path={PATHS_ROUTES.LOGIN} index
                       element={<PageLogin/>}/>


                <Route element={<PublicRoutes/>}>

                    <Route path={PATHS_ROUTES.FORGOT_PASSWORD}
                           element={<PageForgotPassword/>}/>
                    <Route path={PATHS_ROUTES.RESET_PASSWORD}
                           element={<PageResetPassword/>}/>
                    <Route path={PATHS_ROUTES.INVITE} exact
                           element={<PageInvite/>}/>
                    <Route path={PATHS_ROUTES.CONFIRM_EMAIL}
                           element={<PageConfirmEmail/>}/>

                    <Route path={PATHS_ROUTES.LOGOUT}
                           element={<PageLogout/>}/>
                    <Route path={PATHS_ROUTES.INVALID_INVITE}
                           element={<PageInvalidInvite/>}/>
                </Route>
            </Route>


            //PRIVATE ROUTES
            <Route element={<AuthenticatedRoutes/>}>
                <Route element={<LayoutAuthSystem/>}>
                    <Route path={PATHS_ROUTES.HOME} index
                           element={<PageWelcome/>}/><
                    Route path={PATHS_ROUTES.CREATOR_INVITES} index
                          element={<PermissionRoute
                              hasPermission={hasCreatorPermission}
                              children={<PageCreatorInvites/>}/>}/>/>
                    <Route path={PATHS_ROUTES.CREATE_PROFILE} exact
                           element={<PageCreateProfile/>}/>
                    <Route path={PATHS_ROUTES.PROFILE} exact
                           element={<PageProfile/>}/>
                    <Route path={PATHS_ROUTES.DELETE_PROFILE}
                           element={<PageDeleteProfile/>}/>
                    <Route path={PATHS_ROUTES.CREATE_PROFILE_CREATOR} exact
                           element={<PageCreateProfileCreator/>}/>
                    <Route path={PATHS_ROUTES.AUTH_CONFIRM_EMAIL}
                           element={<PageConfirmEmail/>}/>
                    <Route path={PATHS_ROUTES.WELCOME}
                           element={<PageWelcome/>}/>
                </Route>

            </Route>


            <Route path="/page_not_found" exact element={<PageNotFound404/>}/>
            <Route path="*" exact
                   element={<Navigate to={'/page_not_found'}/>}/>
        </Routes>
    );
};
