import React from 'react';
import styled from 'styled-components';
import {gray_2, neutral_3, primary} from "../../utils/Colors";
import {Col, Row, Space, Tooltip} from "antd";
import InputField from "../data_entry/InputField";
import PrimeIcon from "./PrimeIcon";
import copy from 'copy-to-clipboard';
import {ToastNotification} from "../feedback/ToastNotification";

const Container = styled.div`
    width: 100%;
    padding: 14px 24px;
    border-radius: 5px;
    background: ${neutral_3};
`

const Title = styled.div`
    white-space: nowrap;
    font-size: 10px;
    color: ${gray_2};
`
const Copy = styled.div`
    &:hover .input_icon {
        background: ${primary};

        i {
            color: white;
        }
    }

    * {
        cursor: copy;
    }
`

const Count = styled.div`
    border-radius: 3px;
    width: 32px;
    height: 48px;
    padding: 12px 10px;
    background: linear-gradient(to bottom, #7152F6 0%, #9F8AFB 50%, #614DB7 86%, #372488 100%);
    font-weight: bold;
    color: white;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export default function InviteCard({title, url, amount=0, ...props}) {


    function handleCopy() {
        copy(url)
        ToastNotification('Copiado')
    }


    return (<Container size={8}>
        <Row gutter={[42, 10]}>
            <Col xs={2} sm={8}>
                <Title>Convites disponíveis</Title>
                <Counter amount={amount}/>
            </Col>
            <Col xs={24} sm={16}>
                <Title>{title}</Title>
                <Tooltip title={'Clique para copiar'}>
                    <Copy onClick={handleCopy}>
                        <InputField size={'large'} value={url}
                                    addonBefore={<PrimeIcon
                                        iconName={'pi-link'}/>}/>
                    </Copy>
                </Tooltip>
            </Col>
        </Row>
    </Container>)
}


function Counter({amount}) {


    const _amount = amount.toString().padStart(3, '0').split('')

    return (<Space>
        {_amount.map((value) => (<Count>{value}</Count>))}
    </Space>)

}
