import React from 'react'
import styled from 'styled-components';
import useBreakPoints from "../../hooks/useBreakPoints";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${({isMdBreakPoint}) => (isMdBreakPoint? 34: 16)}px;
`
export default function LimitContent({children}){

    const {isMdBreakPoint} = useBreakPoints()

    return (
        <Container isMdBreakPoint={isMdBreakPoint}>
            {children}
        </Container>
    )
}