import React from 'react';
import styled from 'styled-components';
import {primary} from "../../utils/Colors";
import {Flex, Space} from "antd";
import {toCompactNumber} from "../../utils/Utils";


const Amount = styled.span`
    font-size: 22px;
    width: 50px;
    line-height: 32px;
    font-weight: bold;
`

const Title = styled.span`
    color: ${primary};
    font-size: 14px;
    font-weight: bold;
    text-transform: lowercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export default function InviteInfo({amount, title, ...props}) {

    function formatNumber(){
        if(amount < 100){
            return amount.toString()?.padStart(3, '0')
        }
        return toCompactNumber().format(amount)
    }
    return (<Flex gap={0} align={'center'}>
        <Amount>{formatNumber()}</Amount>
       {/*<Amount>{toCompactNumber(amount)?.toString()?.padStart(3, '0')}</Amount>*/}
       <Title>{title}</Title>
    </Flex>)
}

