import React from 'react';
import styled from 'styled-components';
import {gray, neutral_1, primary} from "../../utils/Colors";
import {Flex, Space} from "antd";
import {NavLink} from "react-router-dom";
import LimitContent from "./LimitContent";
import PrimeIcon from "../other/PrimeIcon";
import Divider from "../other/Divider";
import useBreakPoints from "../../hooks/useBreakPoints";
import SocialMedias from "../other/SocialMedias";
import {PATHS_ROUTES} from "../../constans/PathsRoutes";

const Container = styled.div`
    padding: ${({isPhone}) => isPhone ? '18px 14px' : '30px 30px 18px'};
    background: ${neutral_1};

`



const LINKS = [
    {
        link: '',
        title: 'Sobre o FFF'
    },
    {
        link: '',//todo falta adicionar link e tradução
        title: 'Política de Privacidade'
    },
    {
        link: PATHS_ROUTES.TERMS_OF_SERVICE,
        title: 'Termos de Uso'
    },

]

const StyledLink = styled(NavLink)`
    font-size: ${({isPhone}) => isPhone ? '10px' : '14px'};
    color: ${gray};
`

const Address = styled.span`
    font-size: ${({isPhone}) => isPhone ? '10px' : '14px'};
    color: ${gray};
    text-align: center;
    width: 100%;
    display: inline-block;
`


export default function Footer() {
    const {isPhone, isTablet} = useBreakPoints()

    return (<Container isPhone={isPhone}>
        <LimitContent>
            <Flex align={'center'} justify={'space-between'} vertical={isPhone}
                  gap={12}>
                <SocialMedias/>
                <Space size={15}>
                    {LINKS.map(({link, title}, index) => (
                        <StyledLink isPhone={isPhone} to={link}
                                    key={`links_${index}`}>
                            {title}
                        </StyledLink>
                    ))}
                </Space>
            </Flex>
            {/*<Divider marginTop={isPhone ? 12 : 30}*/}
            {/*         marginBottom={isPhone ? 12 : 16} dashed color={'red'}/>*/}
            {/*<Address isPhone={isPhone}>*/}
            {/*    <strong>FANDOM GLOBAL, </strong>*/}
            {/*    140 Main Street, PO BOX 1200, Road*/}
            {/*    Town, Tortola, Ilhas Virgens Britânicas*/}


            {/*</Address>*/}
        </LimitContent>
    </Container>)
}

