export const IMAGE_MAX_SIZE = 10
export const FILE_MAX_SIZE = 10

export const YES_OR_NO_OPTIONS = [{label: 'Não', value: false}, {label: 'Sim', value: true} ]

function createAllTypeList() {

    const all_types = []
    Object.entries(this).forEach(([_, value]) => {
        const NOT_FUNCTION = !(typeof (value) === "function")
        if (NOT_FUNCTION) {
            all_types.push(value)
        }
    })
    return all_types

}

export const FILE_TYPES = {
    IMAGES: {
        JPG: 'image/jpg',
        PNG: 'image/png',
        GIF: 'image/gif',
        JPEG: 'image/jpeg',
        ALL:createAllTypeList
    },
    FILES: {
        PDF: 'application/pdf',
        ZIP: 'application/zip',
        ALL: createAllTypeList
    },
    format: (types = []) => {
        return types.reduce((acc, currentValue) => `${acc} ${currentValue},`, '')
    }

}
