import React, {useState} from 'react';
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import {ReactComponent as LogoFFF} from '../../../assests/imgs/logo_fff.svg'
import {Card, Typography, Form as FormAntd, Row, Col, Flex} from "antd";
import styled from 'styled-components'
import Form from "../../../components/data_entry/form/Form";
import InputFormField
    from "../../../components/data_entry/form/InputFormField";
import PrimeIcon from "../../../components/other/PrimeIcon";
import Button from "../../../components/button/Button";
import PasswordFormField
    from "../../../components/data_entry/form/PasswordFormField";
import useDefaultGetRequest from "../../../hooks/useDefaultGetRequest";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import {gray_2} from "../../../utils/Colors";
import LimitContent from "../../../components/layout/LimitContent";
import {BuildFieldErrorsDict, formatPath} from "../../../utils/Utils";
import api from "../../../services/api";
import {PATHS_API} from "../../../constans/PathsApi";
import LoginCard from "../../../components/other/LoginCard";
import useBreakPoints from "../../../hooks/useBreakPoints";


const StyledLogoFFF = styled(LogoFFF)`
    margin-bottom: ${({isPhone}) => isPhone ? '0px' :'73px'};
    max-width: 100%;
`

const Title = styled.h3`
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
`
const StyledParagraph = styled(Typography.Paragraph)`
    max-width: 484px;
    color: ${gray_2};
    //margin: 0 auto;
`

const FORM_NAMES = {
    EMAIL: 'email',
    USERNAME: 'username',
    PASSWORD: 'password',
}


export default function PageInvite() {
    const navigate = useNavigate()
    const [form] = FormAntd.useForm()
    const [isLoadingCreateUser, setIsLoadingCreateUser] = useState(false)
    const {code_invite} = useParams()
    const {isPhone, isTablet, isDesktop} = useBreakPoints()
    const {
        data: invite,
        isLoading: isLoadingInvite
    } = useDefaultGetRequest({
        url: formatPath(PATHS_API.GET_INVITE, {code_invite: code_invite}),
        makeRequest: !!code_invite,
        onOk: (data) => {
            form.setFieldsValue({
                [FORM_NAMES.EMAIL]: data[FORM_NAMES.EMAIL],
                [FORM_NAMES.USERNAME]: data[FORM_NAMES.USERNAME],
            })
        },
        onError: (error) => {
            navigate(PATHS_ROUTES.INVALID_INVITE)
            ToastNotification('Convite inválido', 'error')
        },
        dependence: [code_invite]
    })


    async function handleSubmit(data) {
        try {
            setIsLoadingCreateUser(true)
            const response = await api.post(formatPath(PATHS_API.CONSUME_INVITE, {code_invite: code_invite}), data);
            navigate(formatPath(PATHS_ROUTES.CONFIRM_EMAIL, {
                user_id: response.data.id,
                email: data.email
            }))

        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false)
        } finally {
            setIsLoadingCreateUser(false);
        }
    }


    const isLoading = isLoadingInvite || isLoadingCreateUser

    return (<>
        <LimitContent>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={11} lg={12}>
                    <Flex vertical align={isDesktop? 'start': 'center'}>
                        <StyledLogoFFF isPhone={isPhone}/>
                        <StyledParagraph>
                            Oi, <strong>{invite.name}</strong>, que bom ver
                            você
                            por aqui. Estamos nos preparando para o lançamento
                            do <strong>FunForFan</strong> e tivemos o carinho
                            de
                            reservar seu usuário para garantir que ninguém tome
                            o
                            seu lugar.
                        </StyledParagraph>
                        <StyledParagraph>
                            Ao reservar seu usuário agora você também garante
                            alguns benefícios e vantagens quando o <strong>Fun
                            For
                            Fan</strong> for aberto ao público. Vamos lá?
                        </StyledParagraph>
                    </Flex>
                </Col>
                <Col xs={24} md={13} lg={12}>
                    <LoginCard padding={isDesktop? '44px 69px': '32px'}>
                        <Title>
                            Reserve seu usuário
                        </Title>
                        <Form form={form} disabled={isLoading}
                              onFinish={handleSubmit}>

                            <Row>
                                <Col xs={24}>
                                    <InputFormField
                                        form={{
                                            label: "Usuário",
                                            required: true,
                                            name: FORM_NAMES.USERNAME,
                                        }}
                                        placeholder={'Usuário'}
                                        addonBefore={<PrimeIcon
                                            iconName={'pi-at'}/>}/>
                                </Col>
                                <Col xs={24}>
                                    <InputFormField
                                        form={{
                                            label: "E-mail",
                                            required: true,
                                            type: 'email',
                                            name: FORM_NAMES.EMAIL,
                                        }}
                                        placeholder={'E-mail'}
                                        addonBefore={<PrimeIcon
                                            iconName={'pi-envelope'}/>}/>
                                </Col>
                                <Col xs={24}>
                                    <PasswordFormField
                                        required
                                        form={{
                                            label: 'Senha',
                                            required: true,
                                            name: FORM_NAMES.PASSWORD
                                        }}
                                        placeholder={'Crie sua senha'}
                                        addonBefore={<PrimeIcon
                                            iconName={'pi-lock'}/>}/>
                                </Col>
                                <Col xs={24} style={{ marginTop: '16px' }}>
                                    <Button onClick={form.submit}
                                                      loading={isLoading}
                                                      block>
                                    Criar conta</Button></Col>

                            </Row>
                        </Form>
                    </LoginCard>
                </Col>
            </Row>
        </LimitContent>
    </>)
}
