import React, {useEffect} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import {isAuthenticated} from "../../../utils/Auth";
import {ReactComponent as LogoFFF} from '../../../assests/imgs/logo_fff.svg'
import {Typography, Form as FormAntd, Row, Col} from "antd";
import styled from 'styled-components'
import Form from "../../../components/data_entry/form/Form";
import InputFormField
    from "../../../components/data_entry/form/InputFormField";
import PrimeIcon from "../../../components/other/PrimeIcon";
import Button from "../../../components/button/Button";
import PasswordFormField
    from "../../../components/data_entry/form/PasswordFormField";
import api from "../../../services/api";
import {BuildFieldErrorsDict} from "../../../utils/Utils";
import {PATHS_API} from "../../../constans/PathsApi";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import {useAuthUserContext} from "../../../context/AuthUserContext";
import {Title} from "../../../components/other/GenericComponents";
import useBreakPoints from "../../../hooks/useBreakPoints";
import LoginCard from "../../../components/other/LoginCard";
import useQuery from "../../../hooks/useQuery";


const StyledLogoFFF = styled(LogoFFF)`
    margin: 0 auto 19px;
    width: 100%;
    //display: inline-block;
`


const FORM_NAMES = {
    USERNAME: 'username',
    PASSWORD: 'password',
}


export default function PageLogin() {
    const navigate = useNavigate()
    const [form] = FormAntd.useForm()
    const [isLoading, setIsLoading] = React.useState(false)
    const {saveUserTokens, user, hasAutheticatedUser} = useAuthUserContext()
    const {isPhone} = useBreakPoints()
    const query = useQuery();

    useEffect(() => {
        if (hasAutheticatedUser()) {
            redirectToNextPage()
        }
    }, [user]);

    const redirectToNextPage = () => {

        if (query.has("next")) {
            return navigate(query.get("next"))

        }
        if(user.profile_creator?.want_to_earn_more){
            return navigate(PATHS_ROUTES.CREATOR_INVITES)
        }
        return navigate(PATHS_ROUTES.HOME)
    }

    async function handleSubmit(data) {
        try {
            setIsLoading(true)
            const {
                data: {
                    access,
                    refresh
                }
            } = await api.post(PATHS_API.LOGIN, data);

            saveUserTokens(access, refresh)


            ToastNotification('Sucesso')
        } catch (error) {
            BuildFieldErrorsDict(error, null)
        } finally {
            setIsLoading(false);
        }
    }


    return (<>
        <StyledLogoFFF/>
        <LoginCard isPhone={isPhone} padding={'44px 69px'}>
            <Title>
                Login
            </Title>
            <Form form={form} disabled={isLoading} onFinish={handleSubmit}>
                <Row>
                    <Col xs={24}>
                        <InputFormField
                            form={{
                                label: "Usuário",
                                required: true,
                                name: FORM_NAMES.USERNAME,
                            }}
                            placeholder={'Digite seu e-mail ou usuário'}
                            addonBefore={<PrimeIcon
                                iconName={'pi-envelope'}/>}/>
                    </Col>
                    <Col xs={24}>
                        <PasswordFormField
                            required
                            form={{
                                label: "Senha",
                                required: true,
                                name: FORM_NAMES.PASSWORD
                            }}
                            placeholder={'Senha'}
                            addonBefore={<PrimeIcon
                                iconName={'pi-lock'}/>}/>
                    </Col>
                    <Col xs={24}> <Button onClick={form.submit}
                                          loading={isLoading}
                                          block>Acessar</Button></Col>
                    <Col xs={24} style={{textAlign: 'center', marginTop: 14}}>
                        <NavLink to={PATHS_ROUTES.FORGOT_PASSWORD}>
                            <Typography.Text type={'secondary'}>Esqueci a
                                senha</Typography.Text></NavLink>
                    </Col>
                </Row>
            </Form>
        </LoginCard>
    </>)
}