import {UserProvider} from "./AuthUserContext";
import {PermissionsProvider} from "./PermissionsContext";


const providers = [UserProvider, PermissionsProvider];

export default function AppContenxt(props) {
    const { children, ...rest } = props;
    return (
        <>
            {providers.reduceRight((acc, Comp) => {
                return <Comp {...rest}>{acc}</Comp>
            }, children)}
        </>
    )
}