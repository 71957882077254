import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import {ReactComponent as LogoFFF} from '../../../assests/imgs/logo_fff.svg'
import {Typography} from "antd";
import styled from 'styled-components'

import Button from "../../../components/button/Button";

import {Title} from "../../../components/other/GenericComponents";
import useBreakPoints from "../../../hooks/useBreakPoints";

import {primary} from "../../../utils/Colors";
import SocialMedias from "../../../components/other/SocialMedias";


const StyledLogoFFF = styled(LogoFFF)`
    margin: 0 auto 19px;
    width: 100%;
    //display: inline-block;
`


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 508px;
    margin: 0 auto;
    text-align: center;
    gap: 10px;

`


const SocialText = styled.span`
    font-size: 14px;
    color: ${primary};
    font-weight: bold;
`

export default function PageInvalidInvite() {
    const navigate = useNavigate()
    const {isPhone} = useBreakPoints()
    const {username, name} = useParams();


    return (<>
        <StyledLogoFFF/>
        <StyledContainer isPhone={isPhone} padding={'44px 69px'}>
            {/*<UserName>USER RESERVADO</UserName>*/}
            <Typography.Text type={'secondary'}>
                {username}
            </Typography.Text>
            <Title>
Convite inválido
            </Title>
            <Typography.Text type={'secondary'}>
                 Lamentamos o inconveniente, mas o link de convite que você está usando pode ter expirado. Solicite um novo link de convite para ingressar no <strong>FunForFan</strong>. Agradecemos sua paciência e compreensão.

            </Typography.Text>
            <Button
                onClick={() => navigate(PATHS_ROUTES.LOGIN)}>Realizar
                login</Button>
            <SocialText>Acompanhe as novidades em nossas redes
                sociais</SocialText>
            <SocialMedias/>
        </StyledContainer>
    </>)
}