import {useLocation} from "react-router-dom";
import React, {useState} from "react";
import {Upload} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {getBase64} from "../utils/Utils";
import {ToastNotification} from "../components/feedback/ToastNotification";

export default function useUpload({
                                      accept,
                                      maxSize,
                                      onChange,
                                      maxCount,
                                      ...props
                                  }) {
    //todo falta criar o onRemove para uma lista de arquivos
    const [fileList, setFileList] = useState([]);

    function onRemove(){
        setFileList([])
        onChange?.(null)
    }

    const generatePreviewImages = async (fileList) => {
        for await (const file of fileList) {
            if (!file.preview) {
                file.preview = file.url || await getBase64(file);

            }
        }
    };

    async function handleBeforeUpload(file) {

        const NOT_VALID_TYPE = !accept?.includes(file.type)

        if (NOT_VALID_TYPE) {
            ToastNotification("O tipo de arquivo(s) é inválido", 'error')
            return Upload.LIST_IGNORE;
        }

        const NOT_VALID_SIZE = maxSize && file.size / 1024 / 1024 <= maxSize;
        if (NOT_VALID_SIZE) {
            ToastNotification("O tamanho máximo do arquivo é: ${maxSize}MB", 'error')
            return Upload.LIST_IGNORE;
        }
        let newFilesList = []
        if (maxCount === 1) {
            newFilesList.push(file)
        } else if (maxCount === fileList.length) {
            newFilesList = [...fileList]
            ToastNotification("Já atingiu a quantidade máxima de arquivos", 'error')
            return Upload.LIST_IGNORE;
        }

        await generatePreviewImages(newFilesList)
        setFileList(newFilesList)
        onChange?.(newFilesList)
        // return false; // o false indica que vc vai fazer o upload manualmente e não utilizando o action
    }


    return {
        handleBeforeUpload, fileList, onRemove
    }

}