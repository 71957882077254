import React from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import {ReactComponent as LogoFFF} from '../../../assests/imgs/logo_fff.svg'
import {Flex, Form as FormAntd, Typography} from "antd";
import styled from 'styled-components'
import Button from "../../../components/button/Button";
import api from "../../../services/api";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {BuildFieldErrorsDict} from "../../../utils/Utils";
import {PATHS_API} from "../../../constans/PathsApi";
import DeleteImage from "../../../assests/imgs/delete_image.png";
import Divider from "../../../components/other/Divider";
import PrimeIcon from "../../../components/other/PrimeIcon";
import Form from "../../../components/data_entry/form/Form";
import LoginCard from "../../../components/other/LoginCard";
import useBreakPoints from "../../../hooks/useBreakPoints";
import {primary} from "../../../utils/Colors";
import PasswordFormField
    from "../../../components/data_entry/form/PasswordFormField";
import {useAuthUserContext} from "../../../context/AuthUserContext";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";


const StyledLogoFFF = styled(LogoFFF)`
    margin: 0 auto 19px;
    width: 100%;
`

const Title = styled.h3`
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
`


const Text = styled(Typography.Text)`
    max-width: 400px;
    display: block;
    text-align: center;
`


const FORM_NAMES = {
    PASSWORD: 'password',
}


export default function PagesPrivate() {
    const navigate = useNavigate()
    const [showPasswordInput, setShowPasswordInput] = React.useState(false)
    const [deleted, setDeleted] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState()
    const [form] = FormAntd.useForm()
    const {isPhone} = useBreakPoints()
    const {simple_logout} = useAuthUserContext()

    async function handleSubmit(data) {
        try {
            setIsLoading(true)
            const response = await api.post(PATHS_API.DELETE_USER, data);
            simple_logout()
            ToastNotification('Conta deletada com sucesso!')
            setTimeout(() => {
                simple_logout()
            }, [5000])
            setDeleted(true)
        } catch (error) {

            BuildFieldErrorsDict(error, form.setFields, false)
        } finally {
            setIsLoading(false);
        }
    }

    return (<>
        <StyledLogoFFF/>
        <LoginCard>
            <Title>{deleted ? 'Perfil Removido' : 'Desejo remover meu perfil'}</Title>
            <Divider marginTop={isPhone ? 15 : 20}
                     marginBottom={isPhone ? 15 : 21}/>
            <Flex align={'center'} vertical gap={24}>
                {showPasswordInput ? (

                    deleted ? (<>
                        <img src={DeleteImage}/>
                        <Text type={'secondary'}>Seu perfil foi removido de
                            forma definitiva e
                            todos os
                            dados relacionados foram apagados.</Text>

                    </>) : (<Form onFinish={handleSubmit} form={form}
                                  style={{
                                      maxWidth: 377,
                                      margin: '0 auto'
                                  }}>
                        <Text type={'secondary'}
                              style={{marginBottom: 24}}>
                            Insira sua senha para apagar sua conta.
                        </Text>
                        <PasswordFormField
                            required
                            form={{
                                required: true,
                                name: FORM_NAMES.PASSWORD
                            }}
                            placeholder={'Senha'}
                            addonBefore={<PrimeIcon
                                iconName={'pi-lock'}/>}/>
                        <Button block style={{maxWidth: 376}}
                                onClick={form.submit}
                                loading={isLoading}>
                            Desejo apagar meu perfil
                        </Button>


                    </Form>)


                ) : (
                    <>
                        <ExclamationCircleFilled
                            style={{fontSize: 32, color: primary}}/>
                        <Text type={'secondary'}>
                            Atenção, ao seguir adiante seu perfil será
                            permanentemente
                            apagado e o usuário ficará disponível.
                        </Text>
                        <Button block style={{maxWidth: 376}}
                                onClick={() => setShowPasswordInput(true)}
                                loading={isLoading}>
                            Desejo apagar meu perfil
                        </Button>
                    </>
                )}
            </Flex>


        </LoginCard>
    </>)
}