import React from 'react';
import {Form} from "antd";
import styled from 'styled-components';


const StyledFormItem = styled(Form.Item)`
    width: 100%;
`

const TYPE_MESSAGE = {
    'email': {
        type: 'email',
        message: 'Por favor, digite um email válido.'//todo falta traduzir
    },

    'url': {
        type: 'url',
        message: 'URL inválida'
    }
}

export default function FormItem({children, required, rules, type, ...props}) {

    const _rules = rules instanceof Array ? rules : []
    const type_rules = TYPE_MESSAGE[type] ? [TYPE_MESSAGE[type]] : []

    return (
        <StyledFormItem
            normalize={(value) => (typeof value === 'string' ? value.trimStart() : value)}
            rules={[{
                required: required,
                message: 'Este campo é obrigatório'//todo falta traduzir
            }, ...type_rules, ..._rules]}
            {...props}>
            {children}
        </StyledFormItem>
    )
}
