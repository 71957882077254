import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import {ReactComponent as LogoFFF} from '../../../assests/imgs/logo_fff.svg'
import {Card, Typography} from "antd";
import styled from 'styled-components'
import Button from "../../../components/button/Button";
import api from "../../../services/api";
import {BuildFieldErrorsDict, formatPath} from "../../../utils/Utils";
import {PATHS_API} from "../../../constans/PathsApi";
import {ToastNotification} from "../../../components/feedback/ToastNotification";
import Divider from "../../../components/other/Divider";
import {useAuthUserContext} from "../../../context/AuthUserContext";
import {FORBIDDEN_STATUS} from "../../../constans/ApiStatus";
import LoginCard from "../../../components/other/LoginCard";
import useBreakPoints from "../../../hooks/useBreakPoints";


const StyledLogoFFF = styled(LogoFFF)`
    margin: 0 auto 19px;
    width: 100%;
    //display: inline-block;
`

const Title = styled.h3`
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
`


export default function PageConfirmEmail() {
    const navigate = useNavigate()
    const {user} = useAuthUserContext()
    const {user_id, email} = useParams()
    const [isLoading, setIsLoading] = React.useState()
    const {isPhone} = useBreakPoints()

    useEffect(() => {
        if (!user && !email && !user_id) {
            navigate(PATHS_ROUTES.LOGIN)
        }
    }, [user]);

    async function handleResendEmail() {
        try {
            setIsLoading(true)
            const response = await api.get(formatPath(PATHS_API.RESEND_EMAIL, {id: user?.id || user_id}));
            ToastNotification('Email enviado!')
        } catch (error) {
            if(error.response.status === FORBIDDEN_STATUS){
                navigate(PATHS_ROUTES.LOGIN)
            }
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false);
        }
    }

    return (<>
        <StyledLogoFFF/>
        <LoginCard>
            <Title>
                Verifique seu e-mail
            </Title>
            <Divider marginTop={isPhone? 15:20} marginBottom={isPhone? 15: 52} />
            <Typography.Text style={{
                maxWidth: 400,
                display: 'block',
                textAlign: 'center',

                margin: '0 auto 51px'
            }}
                             type={'secondary'}>
                Enviamos um e-mail para o e-mail {user?.email || email},
                verifique
                sua caixa de entrada e clique no link para validar seu
                e-mail e continuar o cadastro.
            </Typography.Text>
            <Button block onClick={handleResendEmail} loading={isLoading}>
                Enviar novamente
            </Button>
        </LoginCard>
    </>)
}