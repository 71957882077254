import {createGlobalStyle} from 'styled-components';
import {blue_gray_200, primary, secondary} from "../../utils/Colors";


const GlobalStyle = createGlobalStyle`
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;

        background: ${blue_gray_200};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${primary};
        border-radius: 10px;
    }


    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
        color: ${primary};
    }

    .ant-input-wrapper > .ant-input-group-addon {
        background-color: ${blue_gray_200};
    }
    
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{
        padding-right: 30px ;
    }

    .ant-input, .ant-input-affix-wrapper, .ant-picker-outlined,
    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector,
    .react-international-phone-country-selector button,
    .react-international-phone-input-container
    .react-international-phone-input, .ant-radio-group, .ant-checkbox-group {
        font-family: var(--default-font_family);
        border: 2px solid ${primary};
    }

    .ant-radio-group, .ant-checkbox-group {
        padding: 12px 20px;
        border-radius: 5px;
    }

    .ant-col > .ant-form-item {
        margin-bottom: 24px;
    }

    .ant-form-item .ant-form-item-label > label {
        height: min-content;
        color: ${primary};
        font-weight: 600;
    }

    .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        content: '';
    }

    .ant-form-large .ant-form-item .ant-form-item-label.ant-col{
        height: 25.65px;
    }

    .ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        visibility: visible;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
    }

    .ant-form-large .ant-form-item .ant-form-item-label.ant-col {
        padding-bottom: 0;
    }

    .ant-form-item {
        margin-bottom: 24px;
    }

    .ant-form-item-label label {
        font-weight: bold;
    }

    .ant-input-disabled {
        color: rgba(0, 0, 0, 0.75) !important;
    }

   .ant-notification .ant-notification-notice-wrapper .ant-notification-notice-close {
        top: 10px;
       right: 10px;
    }

`;

export default GlobalStyle;