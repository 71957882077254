import React from 'react';
import {Input} from "antd";

export default function PasswordField({onBlur, ...props}) {

    function handleOnBlur(e) {
        if (props.value) {
            props.onChange?.(props.value.trim())
        }
        onBlur?.(e)
    }

    return (
        <Input.Password onBlur={handleOnBlur} {...props}/>

    )
}