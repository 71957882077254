import React, {useState, createContext, useEffect, useContext} from 'react';

import api from "../services/api";
import {useNavigate} from "react-router-dom";
import {
    deleteTokens,
    isAuthenticated, saveTokens,
} from "../utils/Auth";
import {PATHS_API} from "../constans/PathsApi";
import {PATHS_ROUTES} from "../constans/PathsRoutes";
import {ToastNotification} from "../components/feedback/ToastNotification";
import {formatPath} from "../utils/Utils";

const AuthUserContext = createContext();

function UserProvider({children}) {
    const [user, setUser] = useState(null);
    const [isLoadingUser, setIsLoadingUser] = useState(true);


    let navigate = useNavigate();


    const get_user = async () => {
        try {
            if (isAuthenticated()) {
                setIsLoadingUser(true)
                const response = await api.get(PATHS_API.AUTH_USER);
                setUser(response.data);
            }
        } catch (error) {
            deleteTokens()
        } finally {
            setIsLoadingUser(false)
        }
    }


    useEffect(() => {
        get_user()

    }, []);


    const refreshUser = () => {
        get_user()
    }


    async function saveUserTokens(access, refresh) {
        saveTokens({
            'access': access,
            'refresh': refresh
        });
        get_user()


    }

    function simple_logout() {
        deleteTokens()
        setUser(null)
    }

    function logout() {
        ToastNotification('Logout realizado com sucesso')

        navigate(formatPath(PATHS_ROUTES.LOGOUT, {
            username: user.username,
            name: user.first_name || 'sem nome'
        }))
        simple_logout()
    }

    function hasAutheticatedUser() {
        return isAuthenticated() && !!user
    }

    return (
        <AuthUserContext.Provider
            value={{
                saveUserTokens,
                hasAutheticatedUser,
                isLoadingUser,
                user, logout, simple_logout,
                refreshUser
            }}>
            {children}
        </AuthUserContext.Provider>
    );
}

export {UserProvider};

export function useAuthUserContext() {
    return useContext(AuthUserContext);
}
