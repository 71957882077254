import React from 'react';
import FormItem from "./FormItem";
import SwitchField from "../SwitchField";

export default function SwitchFormField({form, ...props}) {
    return (
        <FormItem {...form}>

            < SwitchField
                {...props}
            />
        </FormItem>
    )
}