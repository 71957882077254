export function saveTokens({access, refresh}) {
    localStorage.setItem(`access`, access);
    localStorage.setItem(`refresh`, refresh);
}

export function deleteTokens() {
    localStorage.clear();
}

export function isAuthenticated() {
    return !!localStorage.getItem(`access`)
}

export function getAccessToken() {
    return localStorage.getItem(`access`)
}

export function getRefreshToken() {
    return localStorage.getItem(`refresh`)
}

