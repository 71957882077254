import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    Form as FormAntd,
    Row,
    Col,
    Card,
    Typography, Collapse, Space,
} from "antd";
import styled from 'styled-components'

import {useAuthUserContext} from "../../../context/AuthUserContext";
import {Title} from "../../../components/other/GenericComponents";
import HelpImage from "../../../assests/imgs/help.png"
import PrimeIcon from "../../../components/other/PrimeIcon";
import {gray_2} from "../../../utils/Colors";
import Button from "../../../components/button/Button";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import api from "../../../services/api";
import {PATHS_API} from "../../../constans/PathsApi";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import {BuildFieldErrorsDict} from "../../../utils/Utils";
import { useTranslation } from 'react-i18next';

const StyledCard = styled(Card)`

    .ant-card-body {
        padding: 48px 48px;

    }
`
const Image = styled.img`

    width: 100%;
`
const HelpContainer = styled.div`
    max-height: 487px;
    overflow-y: auto;
`


const Answer = styled.p`
    white-space: pre-wrap;
`

export default function PageWelcome() {
    const navigate = useNavigate()
    const {user, refreshUser} = useAuthUserContext()
    const [isLoading, setIsLoading] = useState()
    const { t, i18n } = useTranslation();

    useEffect(() => {
         if(user.profile_creator?.want_to_earn_more){
            return navigate(PATHS_ROUTES.CREATOR_INVITES)
        }
    }, [user.profile_creato?.want_to_earn_more]);

    const items = [

        {
            key: '1',
            label: <CollapseItem title={t('QUESTION_1')}/>,
            children: <Answer>{t('ANSWER_1')}</Answer>,
        },
        {
            key: '2',
            label: <CollapseItem title={t('QUESTION_2')}/>,
            children: <Answer>{t('ANSWER_2')}</Answer>,
        },
        {
            key: '3',
            label: <CollapseItem title={t('QUESTION_3')}/>,
            children: <Answer>{t('ANSWER_3')}</Answer>,
        },
        {
            key: '4',
            label: <CollapseItem title={t('QUESTION_4')}/>,
            children: <Answer>{t('ANSWER_4')}</Answer>,
        },
        {
            key: '5',
            label: <CollapseItem title={t('QUESTION_5')}/>,
            children: <Answer>{t('ANSWER_5')}</Answer>,
        },
        {
            key: '6',
            label: <CollapseItem title={t('QUESTION_6')}/>,
            children: <Answer>{t('ANSWER_6')}</Answer>,
        },
    ];

    async function handleEarnMore() {
        try {
            setIsLoading(true)
            const response = await api.post(PATHS_API.ENABLE_EARN_MORE);
            // refreshUser()
            navigate(PATHS_ROUTES.CREATOR_INVITES)
            ToastNotification('Parabéns, você ativou a opção de obter mais ganhos')
        } catch (error) {

            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false);
        }

    }

    return (<StyledCard>

        <Row gutter={[40, 20]}>
            <Col xs={24} lg={12}>
                <Title>Seu perfil está
                    reservado, {user.first_name}!</Title>
                <Typography.Text type={'secondary'}>
                    Está tudo pronto mas, antes de ir, que tal assistir um
                    vídeo curto explicando como você pode aumentar muito sua
                    receita com seus conteúdo e ainda <strong>receber uma parte
                    dos
                    lucros do FunForFan</strong>? No vídeo explicamos alguns
                    diferenciais de nossa plataforma e mostramos como você pode
                    se tornar um sócio(a) fazendo exatamente o que faz hoje:
                    produzindo conteúdo.
                </Typography.Text>
                <Image style={{marginTop: 40}} src={HelpImage}/>
            </Col>
            <Col xs={24} lg={12}>
                <Title>Tire suas dúvidas</Title>
                <HelpContainer>
                    <Collapse items={items} expandIconPosition={'end'}/>
                </HelpContainer>
                {user.profile_creator &&
                    <Button loading={isLoading} onClick={handleEarnMore}
                            style={{margin: '40px auto', display: 'block'}}>
                        Quero faturar muito mais
                    </Button>}
            </Col>


        </Row>

    </StyledCard>)
}

function CollapseItem({title}) {
    return (
        <Space><PrimeIcon iconName={'pi-question-circle'}/> <Typography.Text
            style={{color: gray_2}}>{title}</Typography.Text></Space>
    )
}
