import React, {useEffect, useMemo} from 'react';
import {useNavigate} from "react-router-dom";
import {
    Form as FormAntd,
    Row,
    Col,
    Card,
    Flex,
    Space,
    Checkbox,
    Typography
} from "antd";
import styled from 'styled-components'
import Form from "../../../components/data_entry/form/Form";
import InputFormField
    from "../../../components/data_entry/form/InputFormField";
import PrimeIcon from "../../../components/other/PrimeIcon";

import {BuildFieldErrorsDict} from "../../../utils/Utils";

import {useAuthUserContext} from "../../../context/AuthUserContext";
import {Title} from "../../../components/other/GenericComponents";
import useBreakPoints from "../../../hooks/useBreakPoints";
import DatePickerFormField
    from "../../../components/data_entry/form/DatePickerFormField";
import SelectFormField
    from "../../../components/data_entry/form/SelectFormField";
import FlagOptionSelect from "../../../components/other/FlagOptionSelect";
import PhoneNumberFormField
    from "../../../components/data_entry/form/PhoneNumberFormField";

import TextAreaFormField
    from "../../../components/data_entry/form/TextAreaFormField";
import ImageUploadFormField
    from "../../../components/data_entry/form/ImageUploadFormField";
import Button from "../../../components/button/Button";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import {PATHS_API} from "../../../constans/PathsApi";
import api from "../../../services/api";
import RadioFormField
    from "../../../components/data_entry/form/RadioFormField";
import {YES_OR_NO_OPTIONS} from "../../../utils/Constants";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import SwitchFormField
    from "../../../components/data_entry/form/SwitchFormField";


const StyledCard = styled(Card)`
    max-width: 700px;
    margin: 0 auto;

    .ant-card-body {
        padding: 48px 48px;

    }
`


const FORM_NAMES = {
    TAGS_MAIN: 'tags_main_ids',
    TAGS_SECONDARY: 'tags_secondary_ids',
    ADULT_CONTENT: 'adult_content',
    BLOCK_USER_BRAZIL: 'block_user_brazil',
}


export default function PageCreateProfileCreator() {
    const navigate = useNavigate()
    const [form] = FormAntd.useForm()
    const [isLoading, setIsLoading] = React.useState(false)
    const {user, refreshUser} = useAuthUserContext()


    async function handleSubmit() {
        try {
            setIsLoading(true)
            const data = await getValuesForm()
            const response = await api.post(PATHS_API.PROFILE_CREATOR, data);
            navigate(PATHS_ROUTES.HOME)
            refreshUser()
            ToastNotification('Sucesso')
        } catch (error) {
            BuildFieldErrorsDict(error, null,)
        } finally {
            setIsLoading(false);
        }
    }


    async function getValuesForm() {
        let data = await form.getFieldsValue()
        data['user'] = user.id
        return data

    }


    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                [FORM_NAMES.NAME]: user.name,
                [FORM_NAMES.USERNAME]: user.name,
            })
        }

    }, [user])

    return (<StyledCard>
        <Form form={form} onFinish={handleSubmit} disabled={isLoading}>
            <Row>
                <Col xs={24}> <Title marginBottom={4}>Pré-configure
                    seu perfil</Title>
                    <Typography.Text type={'secondary'}>
                        O maior objetivo
                        do <strong>FunForFan</strong> é
                        garantir uma experiência incrível para você
                        e para
                        seus fãs. Para isso usaremos <strong>Inteligência
                        Artificial</strong> para apróximar o seu
                        perfil dos
                        usuários que tenham mais chance de
                        assinarem e
                        comprarem seu conteúdo.
                    </Typography.Text></Col>
                <Col xs={24} style={{marginTop: 16}}>
                    <Title marginBottom={4}>
                        Principais tipos de conteúdo<Required/>
                    </Title>
                    <Typography.Text type={'secondary'}>
                        Defina seus três principais tipos de conteúdo
                    </Typography.Text>
                    <SelectFormField
                        request={{
                            url: 'tag',
                        }}
                        form={{
                            required: true,
                            name: FORM_NAMES.TAGS_MAIN,
                        }}
                        mode={'multiple'}
                        key_label={'name'}
                        key_value={'id'}
                        addonBefore={<PrimeIcon
                            iconName={'pi-tags'}/>}/>
                </Col>
                <Col xs={24}>
                    <Title marginBottom={4}>
                        Outros tipos de conteúdo<Required/>
                    </Title>
                    <Typography.Text type={'secondary'}>
                        Escolha outros três tipos de conteúdo que pode
                        produzir.
                    </Typography.Text>
                    <SelectFormField
                        request={{
                            url: 'tag',
                        }}
                        mode={'multiple'}
                        form={{
                            required: true,
                            name: FORM_NAMES.TAGS_SECONDARY,
                        }}
                        key_label={'name'}
                        key_value={'id'}
                        addonBefore={<PrimeIcon
                            iconName={'pi-tags'}/>}/>
                </Col>
                <Col xs={24}>
                    <Row>
                        <Col xs={24} md={8}>
                            <Title marginBottom={4}>
                                Perfil de criador
                            </Title>
                            <Typography.Text type={'secondary'}>
                                Cria conteúdo adulto?
                            </Typography.Text>
                            <SwitchFormField
                                form={{
                                    name: FORM_NAMES.ADULT_CONTENT,
                                }}
                                options={YES_OR_NO_OPTIONS}/>
                        </Col>
                        <Col xs={24} md={12}>
                            <Title marginBottom={4}>
                                Acesso por região
                            </Title>
                            <Typography.Text type={'secondary'}>
                                Bloquear usuários do Brasil?
                            </Typography.Text>
                            <SwitchFormField
                                form={{
                                    name: FORM_NAMES.BLOCK_USER_BRAZIL,
                                }}
                                options={YES_OR_NO_OPTIONS}/>
                        </Col>
                    </Row>

                </Col>



                <Col xs={24}>
                    <Button onClick={form.submit}
                            block
                            loading={isLoading}>
                        Salvar
                    </Button>
                </Col>

            </Row>
        </Form>
    </StyledCard>)
}


function Required() {
    return (<span style={{color: 'red'}}>*</span>)
}