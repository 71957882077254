import React from 'react';

import {Card} from "antd";
import styled from 'styled-components'
import useBreakPoints from "../../hooks/useBreakPoints";


const StyledCard = styled(Card)`
    max-width: 508px;
    margin: 0 auto;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1);

    .ant-card-body {
        padding: ${({isPhone, padding}) => isPhone ? '24px' : padding};

    }
`




export default function LoginCard({padding='24px 30px 70px',children}) {

    const {isPhone} = useBreakPoints()


    return (
        <StyledCard isPhone={isPhone} padding={padding}>
            {children}
        </StyledCard>
    )
}