import React, {useRef} from 'react'
import {Outlet,} from "react-router-dom";
import {Layout} from "antd";
import Footer from "../components/layout/Footer";
import styled from 'styled-components'
import {secondary_2} from "../utils/Colors";
import useBreakPoints from "../hooks/useBreakPoints";
import ActionBar from "../components/layout/ActionBar";
import LimitContent from "../components/layout/LimitContent";

const StyledLayout = styled(Layout)`
    background-color: ${secondary_2};
    min-height: 100vh;
    //display: flex;
    //flex-direction: column;

    .main-content {
        //flex-grow: 1;
    }
`

const StyledContent = styled(Layout.Content)`
    padding: ${({isPhone}) => isPhone ? '16px' : '58px 70px'};

`


export default function LayoutAuthSystem() {
    const {isPhone} = useBreakPoints()
//todo  criar um scroll para o topo quando troca de tela

    return (


        <StyledLayout >
            <ActionBar/>

            <StyledContent className={'main-content'} isPhone={isPhone}>
                <LimitContent>
                    <Outlet/>
                </LimitContent>
            </StyledContent>

            <Footer/>
        </StyledLayout>)
}