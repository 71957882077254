import React from 'react';
import {Input} from "antd";
import InputPrefixIcon from "./InputPrefixIcon";

export default function InputField({innerRef, addonBefore, onBlur, ...props}) {

    function handleOnBlur(e) {
        if (props.value) {

            props.onChange?.(props.value.trim())
        }
        onBlur?.(e)
    }

    return (
        <InputPrefixIcon addonBefore={addonBefore}>
            <Input ref={innerRef} onBlur={handleOnBlur} {...props}/>
        </InputPrefixIcon>
    )
}