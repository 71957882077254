import React from 'react';
import InputField from "../InputField";
import FormItem from "./FormItem";
import PasswordField from "../PasswordField";

export default function PasswordFormField({form, ...props}) {
    return (
        <FormItem {...form}>

            < PasswordField
                {...props}
            />
        </FormItem>
    )
}