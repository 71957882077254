import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import {ReactComponent as LogoFFF} from '../../../assests/imgs/logo_fff.svg'
import {Card, Typography} from "antd";
import styled from 'styled-components'
import api from "../../../services/api";
import {BuildFieldErrorsDict, formatPath} from "../../../utils/Utils";
import {PATHS_API} from "../../../constans/PathsApi";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import Divider from "../../../components/other/Divider";
import Loading from "../../../components/feedback/Loading";
import {saveTokens} from "../../../utils/Auth";
import {useAuthUserContext} from "../../../context/AuthUserContext";


const StyledLogoFFF = styled(LogoFFF)`
    margin: 0 auto 19px;
    width: 100%;
    //display: inline-block;
`
const StyledCard = styled(Card)`
    max-width: 508px;
    margin: 0 auto;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1);

    .ant-card-body {
        padding: 24px 30px 70px;
        text-align: center;

    }
`
const Title = styled.h3`
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
`


export default function PageCheckValidateEmail() {
    const {uuid_email} = useParams()
    const {saveUserTokens} = useAuthUserContext()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    React.useEffect(() => {
        if (!isLoading && uuid_email) {
            async function handleConfirmEmail() {
                try {
                    setIsLoading(true)
                    const {
                        data: {
                            access,
                            refresh
                        }
                    } = await api.post(formatPath(PATHS_API.CONFIRM_EMAIL, {id: uuid_email}));
                    saveUserTokens(access, refresh)
                    navigate(PATHS_ROUTES.CREATE_PROFILE)


                    ToastNotification('Email confirmado com sucesso!')
                } catch (error) {
                    if(error.response.status === 404){
                        ToastNotification('Código de validação inválido!', 'error')
                    }else{
                        BuildFieldErrorsDict(error, null, false)
                    }

                    navigate(PATHS_ROUTES.LOGIN)
                }finally {
                     setIsLoading(false)
                }


            }

            handleConfirmEmail()
        }
    }, [uuid_email]);


    return (<>
        <StyledLogoFFF/>
        <StyledCard>
            <Title>
                Aguarde um momento seu email está sendo validado...
            </Title>
            <Divider marginTop={20} marginBottom={52}/>
            <Loading/>
        </StyledCard>
    </>)
}