import axios from 'axios';
import {
    deleteTokens,
    getAccessToken,
    getRefreshToken,
    isAuthenticated,
    saveTokens
} from "../utils/Auth";
import {BASE_ENDPOINT, DEBUG} from "../settings";
import {PATHS_API} from "../constans/PathsApi";
import {PATHS_ROUTES} from "../constans/PathsRoutes";
import {ToastNotification} from "../components/feedback/ToastNotification";
import {FORBIDDEN_STATUS} from "../constans/ApiStatus";

const LOGIN_EXPIRED = 'Login Expired'
axios.defaults.baseURL = BASE_ENDPOINT

//CONFIGURAÇÃO DO REQUEST
axios.interceptors.request.use((config) => {
    if (isAuthenticated()) {
        config.headers.Authorization = `Bearer ${getAccessToken()}`
    }
    let version = config['version'] || 'v1'
    if (!config['baseURL'].includes(version)) {
        config['baseURL'] += version + '/'
    }
    return config;
}, (error) => {
    return Promise.reject(error);
})

//CONFIGURAÇÃO DO RESPONSE
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        DEBUG && console.log(error)
        const TOKEN_INVALID = error?.response?.data?.code === 'token_not_valid'
        if (error.config && error.response?.status === FORBIDDEN_STATUS &&  TOKEN_INVALID){
            const refresh_token = getRefreshToken()
            if (refresh_token) {
                try {
                    let response = await axios.post(PATHS_API.REFRESH_TOKEN, {'refresh': refresh_token})
                    saveTokens({
                        'access': response.data.access,
                        'refresh': response.data.refresh
                    });
                    error.config.headers.Authorization = `Bearer ${response.data['access']}`
                    return axios.request(error.config);
                } catch (err) {
                    return _logout_and_redirect()
                }
            } else {
                _logout_and_redirect()
            }
        }
        return Promise.reject(error);
    },
);

function _logout_and_redirect() {
    deleteTokens()
    window.location.href = PATHS_ROUTES.LOGIN;
    return Promise.reject({
        'message': LOGIN_EXPIRED,
        'error': LOGIN_EXPIRED,
        'detail': LOGIN_EXPIRED,
        "response": {
            'message': LOGIN_EXPIRED,
            'error': LOGIN_EXPIRED,
            'detail': LOGIN_EXPIRED,
            'data': {
                'message': LOGIN_EXPIRED,
                'error': LOGIN_EXPIRED,
                'detail': LOGIN_EXPIRED,
            }
        }
    })
}

export default axios;