import React from 'react';
import FormItem from "./FormItem";
import SelectField from "../SelectField";

export default function SelectFormField({form, ...props}) {

    return (
        <FormItem {...form}>

            < SelectField

                {...props}
            />
        </FormItem>
    )
}