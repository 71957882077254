import React, {useState} from 'react';
import Button from "../../../../components/button/Button";
import {blue_gray_200, gray_2} from "../../../../utils/Colors";
import {Col, Divider, Empty, Flex, Modal, Row, Typography} from "antd";
import {Title} from "../../../../components/other/GenericComponents";
import useDefaultGetRequest from "../../../../hooks/useDefaultGetRequest";
import {PATHS_API} from "../../../../constans/PathsApi";
import {formatPath} from "../../../../utils/Utils";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import Loading from "../../../../components/feedback/Loading";

const ListContainer = styled.div`
    height: 421px;
    width: 100%;
    overflow-y: auto;
`

export default function ButtonInvites({title, category, ...props}) {
    const [open, setOpen] = useState(false)
    const {
         data,
         isLoading,
        response,
    nextPage
     } = useDefaultGetRequest({
         url: formatPath(PATHS_API.INVITES, {category: category}),
         makeRequest: open,
        dataDefault: [],
        clearData: !open
     })
    function handleClose() {
        setOpen(false)
    }


    return (
        <>
            <Button onClick={() => setOpen(true)} style={{
                marginTop: 25,
                // background: blue_gray_200,
                // color: gray_2
            }}>Quem aceitou meu convite?</Button>

            <Modal open={open} onCancel={handleClose} footer={null}>
                <Flex gap={4} align={'center'} vertical>

                    <Title>{title}</Title>
                    <ListContainer >
                        <InfiniteScroll
                            dataLength={data.length}
                            hasMore={!!response?.data?.next}
                            next={nextPage}
                            height={421}
                            loader={<Loading/>}
                        >

                            {data.length > 0 ? <Item style={{position: 'sticky', background: 'white', opacity: 1, top: '0', zIndex: 99}}
                                      username={<strong>Usuários</strong>}
                                      date={<strong>Data</strong>}/>: (<Empty/>)}

                                {data.map(({username, date_joined}) => (
                                    <Item key={username} username={username}
                                          date={date_joined}/>
                                ))}


                        </InfiniteScroll>
                       </ListContainer>
                    {/*{!isLoading || data.length == 0 && <Empty/>}*/}


                </Flex>
            </Modal>
        </>
    )
}


function Item({username, date, ...props}) {
    return (
        <div {...props}>
            <Row  >
                <Col xs={16}> <Typography.Text
                    type={'secondary'}>{username}</Typography.Text></Col>
                <Col xs={8}><Typography.Text
                    type={'secondary'}> {date}</Typography.Text></Col>
                <Divider style={{margin: '12px 0'}}/>

            </Row>
        </div>
    )
}