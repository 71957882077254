import React from 'react'
import {
    PhoneInput,
    CountryData
} from 'react-international-phone';
import 'react-international-phone/style.css';
import styled from 'styled-components';
import InputPrefixIcon from "./InputPrefixIcon";


const StyledPhoneInput = styled(PhoneInput)`
    height: 41px;
    width: 100%;

    .react-international-phone-input {
        width: 100%;
        height: 100%;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        flex-grow: 1;
    }

    .react-international-phone-country-selector button {
        padding: 8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        height: 100%;
        //border: 1px solid red;

    }


    .ant-form-item-has-error & :is(.react-international-phone-country-selector button,  .react-international-phone-input ) {
        border: 1px solid #ff4d4f;
        //border-right: none;
    }



`

export default function PhoneNumberField({
                                             handleChange,
                                             onChange,
                                             addonBefore,
                                             defaultCountry = 'br',
                                             ...internalProps
                                         }) {


    return (
        <InputPrefixIcon addonBefore={addonBefore}>
            <StyledPhoneInput
                defaultCountry={defaultCountry}
                onChange={(value, meta) => {

                    handleChange?.(meta)
                    onChange?.(value)
                }}
                {...internalProps}
            />
        </InputPrefixIcon>

    );
}