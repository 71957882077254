import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import {ReactComponent as LogoFFF} from '../../../assests/imgs/logo_fff.svg'
import {Card, Col, Form as FormAntd, Typography} from "antd";
import styled from 'styled-components'
import Button from "../../../components/button/Button";
import api from "../../../services/api";
import {BuildFieldErrorsDict, formatPath} from "../../../utils/Utils";
import {PATHS_API} from "../../../constans/PathsApi";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import Divider from "../../../components/other/Divider";
import {useAuthUserContext} from "../../../context/AuthUserContext";
import {FORBIDDEN_STATUS} from "../../../constans/ApiStatus";
import InputFormField
    from "../../../components/data_entry/form/InputFormField";
import PrimeIcon from "../../../components/other/PrimeIcon";
import Form from "../../../components/data_entry/form/Form";
import LoginCard from "../../../components/other/LoginCard";
import useBreakPoints from "../../../hooks/useBreakPoints";
import PasswordFormField
    from "../../../components/data_entry/form/PasswordFormField";


const StyledLogoFFF = styled(LogoFFF)`
    margin: 0 auto 19px;
    width: 100%;
`

const Title = styled.h3`
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
`


const FORM_NAMES = {
    PASSWORD: 'new_password',
    CONFIRM_PASSWORD: 'confirm_password',
}


export default function PageResetPassword() {
    const navigate = useNavigate()
    const {uuid_email} = useParams()
    const [isLoading, setIsLoading] = React.useState()
    const [form] = FormAntd.useForm()
    const {isPhone} = useBreakPoints()

    async function handleSubmit(data) {
        try {
            setIsLoading(true)
            const _data = {
                [FORM_NAMES.PASSWORD]: data[FORM_NAMES.PASSWORD],
                uuid: uuid_email
            }
            const response = await api.post(PATHS_API.RESET_PASSWORD, _data);
            ToastNotification('Senha alterado com sucessor!')
            navigate(PATHS_ROUTES.LOGIN)
        } catch (error) {

            BuildFieldErrorsDict(error, form.setFields, false)
            if(Object.keys(error.response.data).includes('uuid')){
                ToastNotification('Link inválido', 'error')
                navigate(PATHS_ROUTES.LOGIN)
            }
        } finally {
            setIsLoading(false);
        }
    }

    async function handleValidatePassword(rule, confirm_password) {

        const password = await form.getFieldValue(FORM_NAMES.PASSWORD)
        if (password !== confirm_password) {
            return Promise.reject(new Error('Senha e confirmar senha não combinam'));
        }

        return Promise.resolve();
    }

    return (<>
        <StyledLogoFFF/>
        <LoginCard>
            <Title>
                Resetar senha
            </Title>
            <Divider marginTop={isPhone ? 15 : 20}
                     marginBottom={isPhone ? 15 : 21}/>
            <Form onFinish={handleSubmit} form={form}
                  style={{maxWidth: 377, margin: '0 auto'}}>
                <PasswordFormField
                    required
                    form={{
                        label: "Senha",
                        required: true,
                        name: FORM_NAMES.PASSWORD
                    }}
                    placeholder={'senha'}
                    addonBefore={<PrimeIcon
                        iconName={'pi-lock'}/>}/>
                <PasswordFormField
                    required
                    form={{
                        label: "Confirmar senha",
                        required: true,
                        name: FORM_NAMES.CONFIRM_PASSWORD,
                        rules: [{
                            validator: handleValidatePassword
                        }]
                    }}
                    placeholder={'Confirmar senha'}
                    addonBefore={<PrimeIcon
                        iconName={'pi-lock'}/>}/>
                <Button block style={{maxWidth: 376}} onClick={form.submit}
                        loading={isLoading}>
                    Enviar
                </Button>
            </Form>

        </LoginCard>
    </>)
}