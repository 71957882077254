import React from 'react';
import {Form as FormAntd} from "antd";
import {ToastNotification} from "../../feedback/ToastNotification";


export default function Form({children, ...props}) {
    return (
        <FormAntd layout={'vertical'}
                  onKeyPress={(e) => e.code === "Enter" && props?.form?.submit()}
                  onFinishFailed={() => ToastNotification("Por favor, preencha todos os campos obrigatórios", 'error')} // todo falta traduzir
                  scrollToFirstError={true}
                  size={'large'}
                  requiredMark={true} {...props} >
            {children}
        </FormAntd>
    )
}
