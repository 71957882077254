import React from "react";
import styled from 'styled-components';
import {primary} from "../../utils/Colors";
import {Divider as DividerAntd} from "antd";

const StyledDivider = styled(DividerAntd)`
    margin-top: ${({marginTop}) => marginTop}px;
    margin-bottom: ${({marginBottom}) => marginBottom}px;
    border-color: ${primary};
`

export default function Divider({marginTop = 24, marginBottom = 24, ...props}) {
    return <StyledDivider marginTop={marginTop} marginBottom={marginBottom}
                          dashed {...props}/>
}