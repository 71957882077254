import React from 'react';
import styled from 'styled-components';
import {gray_2, green, neutral_5, primary} from "../../utils/Colors";
import {Flex, Space} from "antd";
import PrimeIcon from "./PrimeIcon";


const Title = styled.span`
   color: ${primary};
    white-space: nowrap;
    font-size: 16px;
    line-height: 14px;
        font-weight: 500;
`

const Subtitle = styled.span`
   color: ${gray_2};
    white-space: nowrap;
    font-size: 12px;

    font-weight: ${gray_2};
`

export default function CheckInvite({checked, title, subtitle,...props}) {

    return (<Space size={8} align={'center'} style={{marginBottom: 8}}>
       <PrimeIcon style={{color: checked? green:neutral_5}} iconName={'pi-check-circle'}/>
        <Flex vertical >
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </Flex>
    </Space>)
}

