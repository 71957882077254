import React from 'react';
import {Input} from "antd";
import InputPrefixIcon from "./InputPrefixIcon";

export default function TextAreaField({addonBefore, ...props}) {
    return (
        <InputPrefixIcon addonBefore={addonBefore}>
            <Input.TextArea {...props}/>
        </InputPrefixIcon>
    )
}