const PREFIX_CONFIRM_EMAIL = '/confirm_email'
export const PATHS_ROUTES = {
    //PUBLIC ROUTES
    LOGIN: '/',
    TERMS_OF_SERVICE: '/terms_of_service',
    LOGOUT: '/logout/:username/:name',
    INVALID_INVITE: '/invalid_invite',
    FORGOT_PASSWORD: '/forgot_password',
    RESET_PASSWORD: '/reset_password/:uuid_email',
    INVITE: '/invite/:code_invite',
    CONFIRM_EMAIL: `${PREFIX_CONFIRM_EMAIL}/:user_id/:email`,
    CHECK_VALIDATE_EMAIL: '/check_validate_email/:uuid_email',


    //PRIVATE ROUTES
    HOME: '/home',
        AUTH_CONFIRM_EMAIL: PREFIX_CONFIRM_EMAIL,//usado no userContext
    CREATOR_INVITES: '/creator_invites',
    CREATE_PROFILE: '/create_profile',
    PROFILE: '/profile',
    DELETE_PROFILE: '/delete-profile',
    CREATE_PROFILE_CREATOR: '/create_profile_creator',
    WELCOME: '/welcome',
}
