import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Form as FormAntd, Row, Col, Card, Flex,} from "antd";
import styled from 'styled-components'
import Form from "../../../components/data_entry/form/Form";
import InputFormField
    from "../../../components/data_entry/form/InputFormField";
import PrimeIcon from "../../../components/other/PrimeIcon";
import {BuildFieldErrorsDict, formatPath} from "../../../utils/Utils";
import {useAuthUserContext} from "../../../context/AuthUserContext";
import {Title} from "../../../components/other/GenericComponents";
import DatePickerFormField
    from "../../../components/data_entry/form/DatePickerFormField";
import SelectFormField
    from "../../../components/data_entry/form/SelectFormField";
import FlagOptionSelect from "../../../components/other/FlagOptionSelect";
import PhoneNumberFormField
    from "../../../components/data_entry/form/PhoneNumberFormField";
import TextAreaFormField
    from "../../../components/data_entry/form/TextAreaFormField";
import ImageUploadFormField
    from "../../../components/data_entry/form/ImageUploadFormField";
import Button from "../../../components/button/Button";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import {PATHS_API} from "../../../constans/PathsApi";
import api from "../../../services/api";
import {PATHS_ROUTES} from "../../../constans/PathsRoutes";
import dayjs from "dayjs";
import SwitchFormField
    from "../../../components/data_entry/form/SwitchFormField";


const StyledCard = styled(Card)`

    .ant-card-body {
        padding: 48px 48px;

    }
`


const FORM_NAMES_PROFILE = {
    IMAGE: 'image',
    USERNAME: 'username',
    FIRST_NAME: 'first_name',
    BORN_AT: 'born_at',
    CITY: 'city',
    STATE: 'state',
    COUNTRY: 'country',
    PHONE: 'fone',
    BIO: 'bio',


    SOCIAL_MEDIA: {
        name: 'social_networks',
        TIKTOK: 'tiktok',
        TWITTER: 'twitter',
        INSTAGRAM: 'instagram',
        WEBSITE: 'website',

    }

}

const FORM_NAMES_PROFILE_CREATOR = {
    TAGS_MAIN: 'tags_main',
    TAGS_SECONDARY: 'tags_secondary',
    ADULT_CONTENT: 'adult_content',
    BLOCK_USER_BRAZIL: 'block_user_brazil',
}


export default function PageProfile() {
    const navigate = useNavigate()
    const [form] = FormAntd.useForm()
    const {user, refreshUser} = useAuthUserContext()
    const [isLoading, setIsLoading] = useState()

    const HAS_PROFILE_CREATOR = !!user.profile_creator


    async function handleSubmit() {
        try {
            setIsLoading(true)
            const data = await getValuesForm()


            const promises = []
            promises.push(api.patch(formatPath(PATHS_API.GET_PROFILE, {id: user.profile.id}), data.profile));
            if (!!user.profile_creator) {
                promises.push(api.patch(formatPath(PATHS_API.GET_PROFILE_CREATOR, {id: user.profile_creator.id}), data.profile_creator))
            }

            await Promise.all(promises)
            refreshUser()
            ToastNotification('Perfil atualizado com Sucesso')
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields,)
        } finally {
            setIsLoading(false);
        }
    }


    function generateSocialValue(social_networks) {
        const newSocialNetworks = []

        Object.entries(social_networks).forEach(([key, value]) => {
            if (value) {
                newSocialNetworks.push({
                    channel: key,
                    username: value,
                })
            }
        })

        return newSocialNetworks
    }

    async function getValuesForm() {
        let data = await form.getFieldsValue()
        const newData = {
            'profile': {
                [FORM_NAMES_PROFILE.IMAGE]: data[FORM_NAMES_PROFILE.IMAGE],
                [FORM_NAMES_PROFILE.USERNAME]: data[FORM_NAMES_PROFILE.USERNAME],
                [FORM_NAMES_PROFILE.FIRST_NAME]: data[FORM_NAMES_PROFILE.FIRST_NAME],
                [FORM_NAMES_PROFILE.BORN_AT]: data[FORM_NAMES_PROFILE.BORN_AT],
                [FORM_NAMES_PROFILE.CITY]: data[FORM_NAMES_PROFILE.CITY],
                [FORM_NAMES_PROFILE.STATE]: data[FORM_NAMES_PROFILE.STATE],
                [FORM_NAMES_PROFILE.COUNTRY]: data[FORM_NAMES_PROFILE.COUNTRY],
                [FORM_NAMES_PROFILE.PHONE]: data[FORM_NAMES_PROFILE.PHONE],
                [FORM_NAMES_PROFILE.BIO]: data[FORM_NAMES_PROFILE.BIO],
                [FORM_NAMES_PROFILE.SOCIAL_MEDIA.name]: generateSocialValue(data['social_networks']),
            },
            'profile_creator': {
                'tags_main_ids': data[FORM_NAMES_PROFILE_CREATOR.TAGS_MAIN],
                'tags_secondary_ids': data[FORM_NAMES_PROFILE_CREATOR.TAGS_SECONDARY],
                [FORM_NAMES_PROFILE_CREATOR.ADULT_CONTENT]: data[FORM_NAMES_PROFILE_CREATOR.ADULT_CONTENT],
                [FORM_NAMES_PROFILE_CREATOR.BLOCK_USER_BRAZIL]: data[FORM_NAMES_PROFILE_CREATOR.BLOCK_USER_BRAZIL],
            }
        }
        return newData
    }


    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                [FORM_NAMES_PROFILE.FIRST_NAME]: user.first_name,
                [FORM_NAMES_PROFILE.USERNAME]: user.username,
            })
        }

    }, [user])

    const INITIAL_VALUES = useMemo(() => {//useMemo usado para não ficar atualizando o formBuild
        const data = {
            // [FORM_NAMES_PROFILE.IMAGE]: data[FORM_NAMES_PROFILE.IMAGE],
            [FORM_NAMES_PROFILE.FIRST_NAME]: user.first_name,
            [FORM_NAMES_PROFILE.USERNAME]: user.profile[FORM_NAMES_PROFILE.USERNAME],
            [FORM_NAMES_PROFILE.BORN_AT]: user.profile[FORM_NAMES_PROFILE.BORN_AT],
            [FORM_NAMES_PROFILE.CITY]: user.profile[FORM_NAMES_PROFILE.CITY],
            [FORM_NAMES_PROFILE.STATE]: user.profile[FORM_NAMES_PROFILE.STATE],
            [FORM_NAMES_PROFILE.COUNTRY]: user.profile.country_display,
            [FORM_NAMES_PROFILE.PHONE]: user.profile[FORM_NAMES_PROFILE.PHONE],
            [FORM_NAMES_PROFILE.BIO]: user.profile[FORM_NAMES_PROFILE.BIO],


            [FORM_NAMES_PROFILE.SOCIAL_MEDIA.name]: {
                [FORM_NAMES_PROFILE.SOCIAL_MEDIA.TIKTOK]: user.profile[FORM_NAMES_PROFILE.SOCIAL_MEDIA.name].find(({channel}) => channel === FORM_NAMES_PROFILE.SOCIAL_MEDIA.TIKTOK)?.username,
                [FORM_NAMES_PROFILE.SOCIAL_MEDIA.INSTAGRAM]: user.profile[FORM_NAMES_PROFILE.SOCIAL_MEDIA.name].find(({channel}) => channel === FORM_NAMES_PROFILE.SOCIAL_MEDIA.INSTAGRAM)?.username,
                [FORM_NAMES_PROFILE.SOCIAL_MEDIA.TWITTER]: user.profile[FORM_NAMES_PROFILE.SOCIAL_MEDIA.name].find(({channel}) => channel === FORM_NAMES_PROFILE.SOCIAL_MEDIA.TWITTER)?.username,
                [FORM_NAMES_PROFILE.SOCIAL_MEDIA.WEBSITE]: user.profile[FORM_NAMES_PROFILE.SOCIAL_MEDIA.name].find(({channel}) => channel === FORM_NAMES_PROFILE.SOCIAL_MEDIA.WEBSITE)?.username,
            },


        }
        let profile_creator = {}
        if (user.invite_creator && !!user.profile_creator) {
            profile_creator = {
                [FORM_NAMES_PROFILE_CREATOR.ADULT_CONTENT]: user.profile_creator[FORM_NAMES_PROFILE_CREATOR.ADULT_CONTENT],
                [FORM_NAMES_PROFILE_CREATOR.BLOCK_USER_BRAZIL]: user.profile_creator[FORM_NAMES_PROFILE_CREATOR.BLOCK_USER_BRAZIL],
                [FORM_NAMES_PROFILE_CREATOR.TAGS_MAIN]: user.profile_creator[FORM_NAMES_PROFILE_CREATOR.TAGS_MAIN],
                [FORM_NAMES_PROFILE_CREATOR.TAGS_SECONDARY]: user.profile_creator[FORM_NAMES_PROFILE_CREATOR.TAGS_SECONDARY],
            }
        }
        return {...data, ...profile_creator}
    }, [])


    return (<StyledCard>
        <Form form={form} initialValues={INITIAL_VALUES}
              onFinish={handleSubmit} disabled={isLoading}>
            <Row gutter={[20, 0]}>
                <Col xs={24}><Title>Seu cadastro</Title></Col>
                <Col xs={24} lg={8} xl={6}>
                    <ImageUploadFormField
                        form={{
                            label: "Imagem(Ainda nao funciona)",
                            // required: true,
                            name: FORM_NAMES_PROFILE.IMAGE,
                        }}
                    />
                    {HAS_PROFILE_CREATOR && <SelectFormField
                        request={{
                            url: 'tag',
                        }}
                        form={{

                            label: 'Tags primárias',
                            required: true,
                            name: FORM_NAMES_PROFILE_CREATOR.TAGS_MAIN,
                        }}
                        mode={'multiple'}
                        key_label={'name'}
                        key_value={'id'}
                        addonBefore={<PrimeIcon
                            iconName={'pi-tags'}/>}/>}
                    {HAS_PROFILE_CREATOR && <SelectFormField
                        request={{
                            url: 'tag',
                        }}
                        mode={'multiple'}
                        form={{
                            label: 'Outras tags',
                            required: true,
                            name: FORM_NAMES_PROFILE_CREATOR.TAGS_SECONDARY,
                        }}
                        key_label={'name'}
                        key_value={'id'}
                        addonBefore={<PrimeIcon
                            iconName={'pi-tags'}/>}/>}


                </Col>
                <Col xs={24} lg={8} xl={9}>
                    <Flex vertical>
                        <InputFormField
                            form={{
                                label: "Usuário",
                                required: true,
                                name: FORM_NAMES_PROFILE.USERNAME,
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-at'}/>}/>
                        <InputFormField
                            form={{
                                label: "Nome",
                                required: true,
                                name: FORM_NAMES_PROFILE.FIRST_NAME,
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-user'}/>}/>
                        <DatePickerFormField
                            maxDate={dayjs()}
                            form={{
                                label: "Data de Nascimento",
                                required: true,
                                name: FORM_NAMES_PROFILE.BORN_AT,
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-calendar'}/>}/>
                        <SelectFormField
                            style={{maxWidth: 'auto'}}
                            request={{
                                url: 'countries',
                            }}
                            form={{
                                label: "País",
                                required: true,
                                name: FORM_NAMES_PROFILE.COUNTRY,
                            }}
                            key_label={'name'}
                            key_value={'code'}
                            labelRender={({value}, options) => {

                                const option = options.find(({value}) => value === value)

                                return <FlagOptionSelect
                                    src={option?.object.flag}
                                    title={option?.object.name}/>

                            }}
                            optionRender={({data: {object}, ...props}) => (
                                <FlagOptionSelect src={object.flag}
                                                  title={object.name}/>
                            )}
                            addonBefore={<PrimeIcon
                                iconName={'pi-map-marker'}/>}/>

                        <InputFormField
                            form={{
                                label: "Estado",
                                required: true,
                                name: FORM_NAMES_PROFILE.STATE,
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-map-marker'}/>}/>

                        <InputFormField
                            form={{
                                label: "Cidade",
                                required: true,
                                name: FORM_NAMES_PROFILE.CITY,
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-map-marker'}/>}/>

                        {HAS_PROFILE_CREATOR && <Flex>
                            <SwitchFormField

                                mode={'multiple'}
                                form={{
                                    style: {marginBottom: 0},
                                    // layout: 'horizontal',
                                    label: 'Conteúdo adulto',
                                    name: FORM_NAMES_PROFILE_CREATOR.ADULT_CONTENT,
                                }}
                                key_label={'name'}
                                key_value={'id'}/>
                            <SwitchFormField
                                mode={'multiple'}
                                form={{
                                    // layout: 'horizontal',
                                    label: 'Bloquear Brasil',
                                    name: FORM_NAMES_PROFILE_CREATOR.BLOCK_USER_BRAZIL,
                                }}/>

                        </Flex>}
                    </Flex>
                </Col>
                <Col xs={24} lg={8} xl={9}>
                    <Flex vertical>
                        <PhoneNumberFormField
                            form={{
                                label: "Telefone",
                                required: true,
                                name: FORM_NAMES_PROFILE.PHONE,
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-phone'}/>}/>


                        <InputFormField

                            form={{
                                shouldUpdate: false,
                                label: "TikTok",
                                // required: true,
                                name: [FORM_NAMES_PROFILE.SOCIAL_MEDIA.name, FORM_NAMES_PROFILE.SOCIAL_MEDIA.TIKTOK],

                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-tiktok'}/>}/>
                        <InputFormField
                            form={{
                                label: "X",
                                // required: true,
                                name: [FORM_NAMES_PROFILE.SOCIAL_MEDIA.name, FORM_NAMES_PROFILE.SOCIAL_MEDIA.TWITTER],
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-twitter'}/>}/>
                        <InputFormField
                            form={{
                                label: "Instagram",
                                // required: true,
                                name: [FORM_NAMES_PROFILE.SOCIAL_MEDIA.name, FORM_NAMES_PROFILE.SOCIAL_MEDIA.INSTAGRAM],
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-instagram'}/>}/>
                        <InputFormField
                            form={{
                                label: "Site",
                                // required: true,
                                name: [FORM_NAMES_PROFILE.SOCIAL_MEDIA.name, FORM_NAMES_PROFILE.SOCIAL_MEDIA.WEBSITE],
                            }}
                            addonBefore={<PrimeIcon
                                iconName={'pi-globe'}/>}/>
                        <TextAreaFormField
                            style={{height: 130}}
                            form={{
                                label: "Bio",
                                required: true,
                                name: FORM_NAMES_PROFILE.BIO,
                            }}
                        />

                        <Flex>


                            <Button danger type={'text'} block
                                    onClick={() => navigate(PATHS_ROUTES.DELETE_PROFILE)}
                                    loading={isLoading}>Excluir conta</Button>
                            <Button style={{marginBottom: 20}} block
                                    onClick={form.submit}
                                    loading={isLoading}>Salvar</Button>
                        </Flex>

                    </Flex>
                </Col>

            </Row>
        </Form>
    </StyledCard>)
}


