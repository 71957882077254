import React from 'react';
import {DatePicker} from "antd";
import InputPrefixIcon from "./InputPrefixIcon";
import dayjs from "dayjs";
import {parseDateFormat} from "../../utils/Utils";


export default function DatePickerField({addonBefore, onChange, value, ...props}) {

    function handleChange(value){
        const dateFormated = dayjs(value).format('YYYY-MM-DD')
        onChange?.(dateFormated)
    }
    return (
        <InputPrefixIcon addonBefore={addonBefore}>
            <DatePicker disabledTime value={parseDateFormat(value)} onChange={handleChange}  style={{width: '100%'}} suffixIcon={null}
                        format={'DD/MM/YYYY'} {...props}/>
        </InputPrefixIcon>


    )
}