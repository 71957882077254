const prefix_invite = 'invite/:code_invite'
const prefix_profile_creator = 'profile-creator'
const prefix_profile = 'profile'
const prefix_me = 'auth/me'

export const PATHS_API = {
    LOGIN: 'auth/login',
    REFRESH_TOKEN: 'auth/refresh',
    FORGOT_PASSWORD: 'auth/password_reset',
    RESET_PASSWORD: 'auth/password_reset_confirm',

    AUTH_USER: prefix_me,
    DELETE_USER: `${prefix_me}/remove/`,
    GET_USER: `${prefix_me}/:id`,
    RESEND_EMAIL: `${prefix_me}/:id/send_email_verification`,
    CONFIRM_EMAIL: `${prefix_me}/:id/email_confirmation`,
    CREATOR_INVITES: `${prefix_me}/member_get_member`,
    INVITES: `${prefix_me}/registrations/:category`,

    GET_INVITE: prefix_invite,
    CONSUME_INVITE: `${prefix_invite}/consume`,

    PROFILE: prefix_profile,
    GET_PROFILE: `${prefix_profile}/:id`,

    PROFILE_CREATOR: prefix_profile_creator,
    GET_PROFILE_CREATOR: `${prefix_profile_creator}/:id`,
    ENABLE_EARN_MORE: `${prefix_profile_creator}/enable-want-to-earn-more`,


}

