import React, {useState} from 'react'


import 'react-international-phone/style.css';


import PhoneNumberField from "../PhoneNumberField";
import FormItem from "./FormItem";


export default function PhoneNumberFormField({
                                                 form: {rules = [], ...form},
                                                 defaultCountry = 'br',
                                                 ...props
                                             }) {

    const [currentCountry, setCurrentCountry] = useState()
    const [firstTime, setFirstTime] = useState(false)//gambiarra, nao toque

    function handleChange({country}) {
        const NoCountryORChangeCountry = !currentCountry || country.name !== currentCountry.name
        const defaultMaxCaracteres = 9
        if (NoCountryORChangeCountry) {
            setCurrentCountry({
                ...country,
                amount: country.format ? Array.from(country.format.matchAll(/\./g)).length : defaultMaxCaracteres // usado para validar a quantidade de caracteres no validator
            })
        }
    }

    async function handleValidateFormat(rules, value) {
        if (!firstTime) {//gambiarra, nao toque
            setFirstTime(true)

            return Promise.resolve();
        }
        if (props.required && value === `+${currentCountry?.dialCode}`) {
            return Promise.reject(new Error('Este campo é obrigatório'))
        }
        value = value.replace(`+${currentCountry?.dialCode}`, '')
        if (value.length < currentCountry?.amount) {

            return Promise.reject(new Error('Entre um telefone com formato válido'))
        }

        return Promise.resolve();
    }

    return (

        <FormItem
            rules={[{validator: handleValidateFormat}, ...rules]} {...form}>
            <PhoneNumberField handleChange={handleChange}
                              defaultCountry={defaultCountry} {...props}/>
        </FormItem>


        // </Form.Item>
    )
}
